import { Time } from '@angular/common';
import { IProduct } from '../apitypes';
import { ILocation } from '../apitypes';

export interface IProductNotification {
    date: Time;
    notification: NotificationType
    originalProduct: IProduct,
    transferProduct: IProduct,
    fromLocation: ILocation,
    toLocation: ILocation,
    count: number,
    product: IProduct,
    location: ILocation,
    operation: OperationType
}

export enum NotificationType {
    Create,
    Delete,
    ExpirationDate,
    Transfer,
    Update
} 

export enum OperationType {
    MoveToTrash,
    MoveFromTrash,
    DeletePermanent,
    Unkown
} 