import { Component, OnInit } from '@angular/core';
import { AppStateService } from '../../app-state.service';
import { UserService } from '../../services/user/user.service';
import { DashboardLocation, IProduct, ILocation } from 'src/model/apitypes';
import { NotificationSearchComponent } from '../notification-search/notification-search.component';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { NotificationType, OperationType } from 'src/model/Notifications/IProductNotification';
import { ProductCreate } from 'src/model/Notifications/ProductCreate';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { InfoProductComponent } from 'src/app/dialogs/info-product/info-product.component';
import { ProductService } from 'src/app/services/product/product.service';
import { Router } from '@angular/router';
import { InfoLocationComponent } from 'src/app/dialogs/info-location/info-location.component';
import { LocationService } from 'src/app/services/location/location.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  notificationType = NotificationType;
  operationType = OperationType;
  productCreate = ProductCreate;

  dashboardLocation: DashboardLocation[];
  public disabled = true;

  @BlockUI() blockUI: NgBlockUI;

  constructor(public appState: AppStateService,
    private service: UserService,
    public notificationService: NotificationService,
    private dialog: MatDialog,
    private productService: ProductService,
    public router: Router,
    public locationService: LocationService) {

     }

     isFinish: boolean = false ;

  ngOnInit() {
    this.appState.currentPage = 'Dashboard';
    // this.blockUI.start();
    // this.service.getUserLocationsGeneralInfo().subscribe(
    //   (res: DashboardLocation[]) => {
    //     this.dashboardLocation = res;
    //     this.blockUI.stop();
    //   },
    //   err => {
    //     console.log(err);
    //     this.blockUI.stop();
    //   },
    // );
  }

  checkReportDate(dl: DashboardLocation): Boolean {
    if ( new Date((dl.lastReportTime)).getFullYear() > 1000) {
      return true;
    } else {
      return false;
    }
  }


  moveToSelectedLocation(location: ILocation) {
    this.appState.selectedLocation = location;
    this.productService.SearchProductsForSelectedLocationAndCategory(location.id, null);
    this.productService.createCategoriesListForLocation(location.name);
    this.router.navigate(['/home/product-search']);
  }
  showLocation(location: ILocation) {
    this.dialog.open(InfoLocationComponent, {
      width: '30%',
      data: location
    });
  }

  showProductInfo(product: IProduct) {
    this.service.setProduct(product);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.height = 'auto';

    const ref =  this.dialog.open(InfoProductComponent, dialogConfig);
    ref.afterClosed().subscribe(() => {
    });
  }
}
