import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams } from "@angular/common/http";
import { IProduct, ILocation, InventoryReport, DefaultLanguage } from 'src/model/apitypes';
import { AppComponent } from '../../app.component';
import { AppConfig } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class RecoverPwdService {

  header = new HttpHeaders().set('Content-type', 'application/json');

  constructor(private fb: FormBuilder, private http: HttpClient) {
   }

  formRecoverModel = this.fb.group({
    UserName: ['', Validators.required]
  });

  formChangePwdModel = this.fb.group({
    Passwords: this.fb.group({
      Password: ['', [Validators.required, Validators.minLength(4)]],
      ConfirmPassword: ['', Validators.required]
    }, { validator: this.comparePasswords })
  });

  recover() {
    var body = {
      UserName: this.formRecoverModel.value.UserName,
    };
    return this.http.post(AppConfig.settings.API_Address + '/ResetPassword/Recover', body);
  }

  comparePasswords(fb: FormGroup) {
    const confirmPswrdCtrl = fb.get('ConfirmPassword');
    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb.get('Password').value != confirmPswrdCtrl.value) {
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      } else {
        confirmPswrdCtrl.setErrors(null);
      }
    }
  }
}
