import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';
import { ToastrService } from 'ngx-toastr';
import { AdministratorUserPassword } from 'src/model/adminApiTypes';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    public translate: TranslateService,
    public service: AdministratorService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public userModel: AdministratorUserPassword) { }

  ngOnInit() {
  }

    form: FormGroup = new FormGroup({
    newPassword: new FormControl('', Validators.required),
  });

  onSetPassword(userModel: AdministratorUserPassword){
    if (this.form.value.newPassword != null) {
      userModel.newPassword = this.form.value.newPassword;
    }
    else {
      this.onErrorToast('invalid_password');
      return;
    }
    this.service.setPasswordUserAccount(userModel).subscribe(
      (res:any) => {
        this.dialogRef.close({event:'Success'});
      },
      err => {
        this.onErrorToast('server_error')
      },
    );
  }

  onErrorToast(msg: string){
    let toastrData;
    this.translate.get(msg) .subscribe(value => { toastrData = value; } );
    this.toastr.error(toastrData);
  }

  onClose() {
    this.dialogRef.close();
  }

}
