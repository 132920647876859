import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NotificationService } from '../../services/notification/notification.service';
import { OperationType, NotificationType } from 'src/model/Notifications/IProductNotification';
import { ProductCreate } from 'src/model/Notifications/ProductCreate';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { InfoLocationComponent } from 'src/app/dialogs/info-location/info-location.component';
import { InfoProductComponent } from 'src/app/dialogs/info-product/info-product.component';
import { IProduct, ILocation } from 'src/model/apitypes';
import { UserService } from 'src/app/services/user/user.service';
import * as _ from 'lodash';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-notification-search',
  templateUrl: './notification-search.component.html',
  styleUrls: ['./notification-search.component.scss']
})

export class NotificationSearchComponent implements AfterViewInit {
  elements = Array(20);
  notificationType = NotificationType;
  operationType = OperationType;
  productCreate = ProductCreate;

  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;

  constructor(public notificationService: NotificationService,
              private dialog: MatDialog,
              private service: UserService,
              public calendar: NgbCalendar,
              public formatter: NgbDateParserFormatter) {
                this.toDate = calendar.getToday();
                this.fromDate = calendar.getNext(calendar.getToday(), 'd', -60);
              }

  ngAfterViewInit() {
  }

  showLocation(location: ILocation) {
    this.dialog.open(InfoLocationComponent, {
      width: '30%',
      data: location
    });
  }

  showProductInfo(product: IProduct) {
    this.service.setProduct(product);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.height = 'auto';

    const ref =  this.dialog.open(InfoProductComponent, dialogConfig);
    ref.afterClosed().subscribe(() => {
    });
  }

  dateFilter(notificationDate: any) {
    if (this.toDate && this.fromDate)  {
      const from = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
      const to = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day + 1);
      if (( new Date(notificationDate) >= from) && ( new Date(notificationDate) <= to )) {
        return true;
      }
    }
    if (this.fromDate && !this.toDate) {
        const from = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day );
        const fromplusone = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day + 1);
        if (( new Date(notificationDate) >= from) && ( new Date(notificationDate) <= fromplusone )) {
          return true;
        }
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }
  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }
  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}

