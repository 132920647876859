import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/app-state.service';
import { DefaultLanguage } from 'src/model/apitypes';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  constructor(public appState: AppStateService) { }

  downloadPdf(whichFile: any) {

    let pdfUrl = "";
    let pdfName = "";

    if(this.appState.language === "en-US"){
      if(whichFile == 1){
        pdfUrl = './assets/Detailed_instruction_application.pdf';
        pdfName = 'Detailed instruction for the Mobile Application and WEB Application';
     }
     else if(whichFile == 2){
       pdfUrl = './assets/Instruction_manual_rfid_reader.pdf';
       pdfName = 'Instruction manual RFID Reader';
     }
    }
    else if(this.appState.language === "pl-PL"){
      if(whichFile == 1){
        pdfUrl = './assets/szczegolowa_instrukcja_aplikacji.pdf';
        pdfName = 'Szczegółowa instrukcja obsługi Aplikacji i Aplikacji WEB';
     }
     else if(whichFile == 2){
       pdfUrl = './assets/instrukcja_obslugi_czytnika_zal_1.pdf';
       pdfName = 'Instrukcja obsługi czytnika RFID';
     }
    }
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  openFaq(){
    if(this.appState.language === "en-US"){
      window.open("/faq-en", "_blank");
    }
    else if(this.appState.language === "pl-PL"){
      window.open("/faq", "_blank");
    }
  }

  openRodo(){
    if(this.appState.language === "en-US"){
      let pdfUrl = "";
      let pdfName = "";
      pdfUrl = './assets/Rodo_information_clause.pdf';
        pdfName = 'Rodo information clause';
        FileSaver.saveAs(pdfUrl, pdfName);
    }
    else if(this.appState.language === "pl-PL"){
      window.open("/terms/rodo", "_blank");
    }
  }

  openTerms(){
    if(this.appState.language === "en-US"){
      let pdfUrl = "";
      let pdfName = "";
      pdfUrl = './assets/Terms_of_use_and_cookie_policy.pdf';
        pdfName = 'Terms of use and cookie policy';
        FileSaver.saveAs(pdfUrl, pdfName);
    }
    else if(this.appState.language === "pl-PL"){
      window.open("/terms", "_blank");
    }
  }

  ngOnInit() {
    
  }
  

}
