import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceService } from 'src/app/services/device/device.service';
import { IDevice } from 'src/model/apitypes';

@Component({
    selector: 'app-update',
    templateUrl: './update.component.html',
    styleUrls: ['./update.component.scss']
})

export class UpdateComponent implements OnInit {

    constructor(public service: DeviceService) { }

    keyBoardDevice: any;
    bluetoothDevice: any;
    
    ngOnInit(): void {
       this.loadVersionData();
    }

    loadVersionData(){
      
      //const device: IDevice = { version: "1.45",  url: "http://inventory.issrfid.com/update/update145.apk" };
      //this.keyBoardDevice = device;

      this.service.getBluetoothReaderVersion().subscribe(
        (res: any) => {
          this.bluetoothDevice = res;
        },
        err => {
          console.log(err);
        },
      );

      this.service.getKeyboardReaderVersion().subscribe(
        (res: any) => {
          this.keyBoardDevice = res;
        },
        err => {
          console.log(err);
        },
      );
    }
  
}