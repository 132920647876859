import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { AdminNotificationModel } from 'src/model/adminApiTypes';

@Component({
  selector: 'app-notification-admin',
  templateUrl: './notification-admin.component.html',
  styleUrls: ['./notification-admin.component.scss']
})
export class NotificationAdminComponent implements OnInit {

  notifications$: Observable<AdminNotificationModel[]>;
  notifications: AdminNotificationModel[];
  sortBy = '';
  sortAsc = true;
  sort_str = '';
  searchText = '';
  p: any;
  isShown: boolean = false ;

  constructor(public service: AdministratorService,
    public translate: TranslateService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.loadNotifications();
    if (window.screen.width <= 884) {
      this.isShown = true;
    }
  }

  onSearchKey() {
    this.goToFirstPagination();
  }

  goToFirstPagination() {
    this.p = 1;
  }

  loadNotifications(){
    this.service.getAllUsersNotifications().subscribe(
      (res: any) => {
        this.notifications$ = res;
        console.log(res);
      },
      err => {
        console.log(err);
      },
    );
  }

  onSort(sort_by: any) {
    this.sortBy = sort_by;
    if (this.sortAsc) {
      this.sort_str = sort_by + ' asc';
      this.sortAsc = false;
    } else {
      this.sort_str = sort_by + ' desc';
      this.sortAsc = true;
    }
  }

}
