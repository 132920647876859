import { Component, OnInit } from '@angular/core';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss']
})
export class DashboardAdminComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  constructor(public service: AdministratorService,
    public translate: TranslateService,
    private dialog: MatDialog) {
  }

  ngOnInit() {

  }

  onAddDefaultCategories(): void {
    this.blockUI.start();
    this.service.generateDefaultCategories().subscribe(
      (res: any) => {
        this.blockUI.stop();
      },
      err => {
        console.log(err);
        this.blockUI.stop();
      },
    );
  }

  onSetDefaultCategories(): void {
    this.blockUI.start();
    this.service.setDefaultCategories().subscribe(
      (res: any) => {
        this.blockUI.stop();
      },
      err => {
        console.log(err);
        this.blockUI.stop();
      },
    );
  }


  onAddDefaultCategoriesDialog() {
    let dialogData;
    this.translate.get('add') .subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onAddDefaultCategories();
      }
    });
  }

  onSetDefaultCategoriesDialog() {
    let dialogData;
    this.translate.get('add') .subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onSetDefaultCategories();
      }
    });
  }

}
