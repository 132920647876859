import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { MatDialogRef } from '@angular/material';
import { LocationService } from 'src/app/services/location/location.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  constructor(public service: UserService,
    public dialogRef: MatDialogRef<ReportComponent>,
    public locationService: LocationService) { }

    isShown: boolean = false ;

  ngOnInit() {
    if (window.screen.width <= 884) {
      this.isShown = true;
    }
  }

  onClose() {
    // this.form.reset();
     //this.initializeCreateFormGroup();
     this.dialogRef.close();
   }

   sortedReportList() {
    return this.service.selectedReport.products.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() === b.name.toLowerCase() ? 0 : -1);
  }
}
