import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DecimalPipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { RegistrationComponent } from './user/registration/registration.component';
import { UserService } from './services/user/user.service';
import { ReportService } from './services/report/report.service';
import { LocationService } from './services/location/location.service';
import { LoginComponent } from './user/login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { SearchComponent } from './pages/product-search/product-search.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import * as Material from '@angular/material';
import { ProductComponent } from './dialogs/add-product/add-product.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LocationSearchComponent } from './pages/location-search/location-search.component';
import { LocationComponent } from './dialogs/add-location/add-location.component';
import { ReportSearchComponent } from './pages/report-search/report-search.component';
import { NotificationSearchComponent } from './pages/notification-search/notification-search.component';
import { ReportComponent } from './dialogs/report/report.component';
import { SearchProductPipe } from './pipes/search-product/search-product.pipe';
import { SearchLocationPipe } from './pipes/search-location/search-location.pipe';
import { SearchReportPipe } from './pipes/search-report/search-report.pipe';
import { ProductTrashComponent } from './pages/product-trash/product-trash.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddCategoryDialogComponent } from './dialogs/add-category-dialog/add-category-dialog.component';
import { SortProductPipe } from './pipes/sort/sort-product.pipe';
import { ImportProducutComponent } from './dialogs/import-producut/import-producut.component';
import { DragDropDirective } from './dialogs/drag-drop/drag-drop.directive';
import { I18nModule } from './i18n/i18n.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppConfig } from './app.config';
import { DashboardAdminComponent } from './admin/dashboard-admin/dashboard-admin.component';
import { HomeAdminComponent } from './admin/home-admin/home-admin.component';
import { UsersAdminComponent } from './admin/users-admin/users-admin.component';
import { NotificationService } from './services/notification/notification.service';
import { MoveProductComponent } from './dialogs/move-product/move-product.component';
import { LicenseUserDialogComponent } from './dialogs/administrator/license-user-dialog/license-user-dialog.component';
import { ShowUserLocationDialogComponent } from './dialogs/administrator/show-user-location-dialog/show-user-location-dialog.component';
import { InfoProductComponent } from './dialogs/info-product/info-product.component';
import { InfoLocationComponent } from './dialogs/info-location/info-location.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { StoreService } from './services/store/store.service';
import { TableSettingsComponent } from './dialogs/table-settings/table-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LocationAdminComponent } from './admin/location-admin/location-admin.component';
import { ChangePasswordDialogComponent } from './dialogs/administrator/change-password-dialog/change-password-dialog.component';
import { BlockUIModule } from 'ng-block-ui';
import { ManageCategoriesComponent } from './dialogs/manage-categories/manage-categories.component';
import { CategoryService } from './services/category/category.service';
import { SortUserPipe } from './pipes/sort/sort-user-pipe';
import { UpdateComponent } from './pages/update/update.component';
import { SortLocationPipe } from './pipes/sort/sort-location-pipe';
import { NotificationAdminComponent } from './admin/notification-admin/notification-admin.component';
import { ProductAdminComponent } from './admin/product-admin/product-admin.component';
import { SortNotificationPipe } from './pipes/sort/sort-notification-pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExcelService } from './services/excel.service';
import { RecoverPwdComponent } from './user/recoverpwd/recoverpwd.component';
import { ActiveComponent } from './user/active/active.component';
import { EmailconfirmComponent } from './user/emailconfirm/emailconfirm.component';
import { AboutuserComponent } from './pages/aboutuser/aboutuser.component';
import { TermsComponent } from './user/terms/terms.component';
import { SupportComponent } from './pages/support/support.component';
import { RodoComponent } from './user/rodo/rodo.component';
import { FaqComponent } from './user/faq/faq.component';
import { MatFaqModule } from '@angular-material-extensions/faq';
import { FaqEnComponent } from './user/faq-en/faq-en.component';

export function initializeApp(appConfig: AppConfig) {

  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    RegistrationComponent,
    LoginComponent,
    HomeComponent,
    SearchComponent,
    ProductComponent,
    DashboardComponent,
    LocationSearchComponent,
    LocationComponent,
    ReportSearchComponent,
    NotificationSearchComponent,
    ReportComponent,
    SearchProductPipe,
    SearchLocationPipe,
    SearchReportPipe,
    ProductTrashComponent,
    AboutUsComponent,
    ConfirmationDialogComponent,
    AddCategoryDialogComponent,
    SortProductPipe,
    SortUserPipe,
    ImportProducutComponent,
    DragDropDirective,
    DashboardAdminComponent,
    HomeAdminComponent,
    UsersAdminComponent,
    MoveProductComponent,
    LicenseUserDialogComponent,
    ShowUserLocationDialogComponent,
    InfoProductComponent,
    InfoLocationComponent,
    TableSettingsComponent,
    LocationAdminComponent,
    ChangePasswordDialogComponent,
    ManageCategoriesComponent,
    UpdateComponent,
    SortLocationPipe,
    NotificationAdminComponent,
    ProductAdminComponent,
    SortNotificationPipe,
    RecoverPwdComponent,
    ActiveComponent,
    EmailconfirmComponent,
    AboutuserComponent,
    TermsComponent,
    SupportComponent,
    RodoComponent,
    FaqComponent,
    FaqEnComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    ToastrModule.forRoot({
      progressBar: true
    }),
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    Material.MatToolbarModule,
    Material.MatGridListModule,
    Material.MatFormFieldModule,
    Material.MatInputModule,
    Material.MatRadioModule,
    Material.MatSelectModule,
    Material.MatCheckboxModule,
    Material.MatDatepickerModule,
    Material.MatNativeDateModule,
    Material.MatButtonModule,
    Material.MatSnackBarModule,
    Material.MatTableModule,
    Material.MatIconModule,
    Material.MatPaginatorModule,
    Material.MatSortModule,
    Material.MatDialogModule,
    Material.MatTabsModule,
    Material.MatAutocompleteModule,
    Material.MatSliderModule,
    DragDropModule,
    MatExpansionModule,
    I18nModule,
    NgxPaginationModule,
    BlockUIModule.forRoot(),
    MatFaqModule.forRoot()
  ],
  providers: [
    DecimalPipe,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    NotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ReportService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    LocationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    StoreService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    CategoryService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
  entryComponents:  [
    ProductComponent,
    LocationComponent,
    ReportComponent,
    ConfirmationDialogComponent,
    AddCategoryDialogComponent,
    ImportProducutComponent,
    MoveProductComponent,
    LicenseUserDialogComponent,
    ChangePasswordDialogComponent,
    InfoProductComponent,
    InfoLocationComponent,
    TableSettingsComponent,
    ManageCategoriesComponent
  ]
})
export class AppModule {}
