import { Component, OnInit } from '@angular/core';
import { AppStateService } from '../../app-state.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(public appState: AppStateService) { }

  ngOnInit() {
    this.appState.currentPage = "About us";
  }

}
