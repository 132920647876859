import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { IProduct } from 'src/model/apitypes';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../services/product/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AppConfig } from '../../app.config';
import { AppStateService } from 'src/app/app-state.service';
import { AddCategoryDialogComponent } from '../add-category-dialog/add-category-dialog.component';
import { SearchComponent } from 'src/app/pages/product-search/product-search.component';
import { LocationService } from 'src/app/services/location/location.service';
import { StoreService } from 'src/app/services/store/store.service';
import { IfStmt } from '@angular/compiler';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CategoryService } from 'src/app/services/category/category.service';
import { Observable, of} from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class ProductComponent implements OnInit {
  categories: String[] = [];
  public isDefaultImageSelected: Boolean = false;
  public epcList: String[] = [''];
  epcs: Observable<String[]>;

  @BlockUI() blockUI: NgBlockUI;

  constructor(public service: UserService,
    public productService: ProductService,
    public categoryService: CategoryService,
    public locationService: LocationService,
    public dialogRef: MatDialogRef<ProductComponent>,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public translate: TranslateService,
    public appState: AppStateService,
    public storeService: StoreService) {
  }
  body: any;
  form: FormGroup = new FormGroup({
    $key: new FormControl(0),
    name: new FormControl('', Validators.required),
    location: new FormControl('', Validators.required),
    category: new FormControl(''),
    description: new FormControl(''),
    code: new FormControl(''),
    count: new FormControl(1),
    price: new FormControl(0),
    markup: new FormControl(0),
    tagName: new FormControl(''),
    tagEPC: new FormControl('', Validators.required),
    tagUserData: new FormControl(''),
    tagPassword: new FormControl(''),
    expirationDate: new FormControl(''),
    tagTID: new FormControl(''),
    productGroupName: new FormControl('')
  });
  fileToUpload: File;
  url: String = '';
  files: Array<any> = new Array<any>();
  fileList: FileList;
  defaulltImgUrl: string;
  productId: number;

  initializeCreateFormGroup() {
    this.clearEpcList();
    this.form.setValue({
      $key: 0,
      name: '',
      location: null,
      category: null,
      description: null,
      code: null,
      count: 1,
      price: 0,
      markup: null,
      tagName: null,
      tagEPC: null,
      tagUserData: null,
      tagPassword: null,
      expirationDate: null,
      tagTID: null,
      productGroupName: null
    });
  }
  initializeClearFormGroup(product: IProduct) {
    this.clearEpcList();
    this.form.setValue({
      $key: product.id,
      name: '',
      location: null,
      category: null,
      description: null,
      code: null,
      count: 1,
      price: 0,
      markup: null,
      tagName: null,
      tagEPC: this.getCurrentProductEpcList(),
      tagUserData: null,
      tagPassword: null,
      expirationDate: null,
      tagTID: null,
      productGroupName: null
    });
  }
  initializeUpdateFormGroup(product: IProduct) {
    this.form.setValue({
      $key: product.id,
      name: product.name,
      location: product.location.id,
      category: product.category,
      description: product.description,
      code: product.code,
      count: product.count,
      price: product.price,
      markup: product.markup,
      tagName: product.tagName,
      tagEPC: this.getCurrentProductEpcList(),
      tagUserData: product.tagUserData,
      tagPassword: product.tagPassword,
      expirationDate: product.expirationDate,
      tagTID: product.tagTID,
      productGroupName: product.productGroup ? product.productGroup.name : null
    });
  }


  ngOnInit() {
    if (this.service.getProduct() == null) {
      if (this.storeService.addProductFormStored) {
        this.readStoredFormData();
      } else {
        this.initializeCreateFormGroup();
      }
    } else {
      this.getInitProductEpcList();
      this.initializeUpdateFormGroup(this.service.getProduct());
    }
    this.getDefaultImageUrl();
  }

  getFormBody() {
    let expirationDateWitoutTimezoneOffset = null;
    if (this.form.value.expirationDate != null) {
      expirationDateWitoutTimezoneOffset = new Date(this.form.value.expirationDate);
      expirationDateWitoutTimezoneOffset.setMinutes
      (expirationDateWitoutTimezoneOffset.getMinutes() - expirationDateWitoutTimezoneOffset.getTimezoneOffset());
    }
    return this.body = {
      id: this.form.value.$key,
      name: this.form.value.name,
      locationId: this.form.value.location,
      category: this.form.value.category,
      description: this.form.value.description,
      code: this.form.value.code,
      count: this.form.value.count,
      price: this.form.value.price,
      markup: this.form.value.markup,
      tagName: this.form.value.tagName,
      tagEPCList: this.form.value.tagEPC,
      tagUserData: this.form.value.tagUserData,
      tagPassword: this.form.value.tagPassword,
      expirationDate: expirationDateWitoutTimezoneOffset,
      tagTID: this.form.value.tagTID,
      productGroup: this.form.value.productGroupName
    };
  }

  onSubmit() {
    this.updateEpcList();
    this.updateEpcListInForm();

    this.blockUI.start();
    if (this.service.getProduct() == null) {
      let toastrData;
      this.translate.get('creating_product_failed') .subscribe(value => { toastrData = value; } );
      this.service.createProduct(this.getFormBody()).subscribe(
        (res: any) => {
          this.productService.loadAll();
          this.translate.get('new_product_created') .subscribe(value => { toastrData = value; } );
          this.toastr.success(toastrData, res.name);
          this.publishImageToServer(res.id);
          this.appState.selectedCategory = res.productCategory;
          this.appState.selectedLocation = res.location;
          this.storeService.addProductFormStored = false;
          this.dialogRef.close(true);
          this.onClose(true);
        },
        (err) => {
          if (err.error.message === 'epc_already_exist') {
            this.translate.get(err.error.message).subscribe(value => { toastrData = value; } );
            this.toastr.warning(toastrData);
            this.blockUI.stop();
          } else {
            this.storeService.addProductFormStored = false;
            this.toastr.error(toastrData);
            this.dialogRef.close(false);
            this.blockUI.stop();
          }
        }
      );
    } else {
      let toastrData;
      this.translate.get('updating_product_failed') .subscribe(value => { toastrData = value; } );
      this.service.updateProduct(this.getFormBody()).subscribe(
        (res: IProduct) => {
            this.translate.get('product_updated') .subscribe(value => { toastrData = value; } );
            this.toastr.success(toastrData, res.name);
            this.productService.updateProduct(res);
            this.publishImageToServer(res.id);
            this.onClose(true);
            this.blockUI.stop();
          },
          (err: any) => {
            this.onClose(true);
            console.log(err);
            this.toastr.error(toastrData);

          }
      );
    }
  }

  onClear() {
    if (this.service.getProduct()) {
      this.initializeClearFormGroup(this.service.getProduct());
    } else {
      this.initializeCreateFormGroup();
    }
    this.getDefaultImageUrl();
  }

  onClose(isUpload) {
    this.dialogRef.close(isUpload);
    this.blockUI.stop();
    if (this.service.getProduct() == null) {
      this.updateEpcList();
      this.updateEpcListInForm();
      this.storeService.SaveAddProductData(this.form, this.defaulltImgUrl);
    }
  }


  onSelectFile(files: FileList) {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload = files.item(0);

    const fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(this.fileToUpload);

    fileReader.onload = (event: any) => {
      this.url = event.target.result;
      this.defaulltImgUrl = event.target.result;
    };
  }

  getDefaultImageUrl() {
    if (this.storeService.addProductFormStored && this.service.getProduct() == null){
      this.defaulltImgUrl = this.storeService.ReadAddProductImageUrl();
      this.isDefaultImageSelected = false;
    } else {
      this.defaulltImgUrl = AppConfig.settings.API_Address + '/FileResource/GetImage/' + this.form.controls['$key'].value
      + '/' + this.productService.imageUploadTimeStamp;
      this.isDefaultImageSelected = true;
    }
  }

  onDeleteImage() {
    let dialogData;
    this.translate.get('confirm_delete') .subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.blockUI.start();
        this.url = null;
        this.productService.imageUploadTimeStamp = (new Date()).getTime();
        this.productId = this.getFormBody().id;
        if (this.productId !== 0) {
          this.deleteImageFromServer();
        } else {
          this.getDefaultImageUrl();
          this.blockUI.stop();
        }
      }

    });
  }

  deleteImageFromServer() {
    this.service.deleteImage(this.productId)
        .subscribe((result: any) => {
          this.productService.imageUploadTimeStamp = (new Date()).getTime();
          this.getDefaultImageUrl();
          this.blockUI.stop();
        },
        (err) => {
          this.blockUI.stop();
          console.log(err);
        }
      );
  }

  publishImageToServer(productId: number) {
    if (this.url == null) {
      return;
    }
    if (this.fileToUpload == null) {
      return;
    }

    this.files.push({ data: this.fileToUpload, fileName: this.fileToUpload.name });

    let fileToUpload = this.fileToUpload;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('ProductId', productId.toString())

    this.service.uploadProductPicture(formData)
      .subscribe((result: string) => {
        this.productService.imageUploadTimeStamp = (new Date()).getTime();
    });
  }

  addNewCategory() {
    let dialogTitle;
    this.translate.get('add_new_category').subscribe(value => { dialogTitle = value; } );
    const dialogRef = this.dialog.open(AddCategoryDialogComponent, {
      width: '250px',
      data: {id: 0, title: dialogTitle, category: null, isEdit: false, newCategory: null}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.blockUI.start();
        let toastrData;
        this.service.addCategory(result.category).subscribe(
          async (res: any) => {
            this.translate.get('create_category_success') .subscribe(value => { toastrData = value; } );
            this.toastr.success(toastrData);
            await this.categoryService.LoadUserCategories();
            this.form.patchValue({category: result.category});
            this.blockUI.stop();
          },
          (err) => {
            this.translate.get('create_category_failed') .subscribe(value => { toastrData = value; } );
            console.log(err);
            this.blockUI.stop();
          }
         );
      }
    });
  }

  readStoredFormData() {
    this.form.setValue(this.storeService.ReadAddProductData().value);
    this.clearEpcList();
    this.epcList = this.storeService.ReadAddProductData().value.tagEPC;
    this.updateEpcListInForm();
  }


  /* Metody do obsługi dodawania wielu EPC  */
  getInitProductEpcList() {
    this.epcList = this.service.getProduct().epcs;
    if(this.epcList.length == 0){
      this.epcList = [''];
    }
  }
  removeEpcInput(index: any) {
    this.epcList.splice(index, 1);
  }
  addEpcInput() {
    this.updateEpcList();
    this.epcList.splice(0, 0, '');
    setTimeout(() => {
      const inputElement = <HTMLInputElement>document.getElementById('epc0');
      inputElement.focus();
      inputElement.value = 'EPC';
    }, 100);
  }
  updateEpcList() {
    const newEpcList = [];
    this.epcList.forEach((epc, index) => {
      const inputElement = <HTMLInputElement>document.getElementById('epc' + index.toString());
      newEpcList.push(inputElement.value);
    });
    this.epcList = [];
    this.epcList = newEpcList;
  }
  clearEpcList() {
    this.epcList.length = 0;
    this.epcList = [''];
    this.updateEpcListInForm();
  }
  updateEpcListInForm() {
    this.form.controls['tagEPC'].setValue(this.epcList);
  }
  getCurrentProductEpcList() {
    return this.epcList;
  }
}
