import { Component, OnInit, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { AppStateService } from '../../app-state.service';
import { UserService } from '../../services/user/user.service';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { IProduct, ApiDeleteProduct } from 'src/model/apitypes';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../services/product/product.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '../../app.config';
import { LocationService } from 'src/app/services/location/location.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CategoryService } from 'src/app/services/category/category.service';

@Component({
  selector: 'app-product-trash',
  templateUrl: './product-trash.component.html',
  styleUrls: ['./product-trash.component.scss']
})
export class ProductTrashComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public pipe: DecimalPipe,
    public appState: AppStateService,
    private service: UserService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private productService: ProductService,
    private notificationService: NotificationService,
    public translate: TranslateService,
    public locationService: LocationService,
    public categoryService: CategoryService) {

  }

  BaseURI = AppConfig.settings.API_Address;

  AllProducts: IProduct[] = [];
  products$: Observable<IProduct[]> ;
  filter = new FormControl('');

  selectedProducts = 0;
  searchText = '';
  isChecked = '';
  delete: any;
  p: any;

  ngOnInit() {
    this.appState.currentPage = 'Products in trash';
    this.products$ = this.productService.deletedProducts;
    this.productService.cleanDeletedSelectedProducts();
    //this.productService.loadAll();
  }

  public Search(text: string, pipe: PipeTransform): IProduct[] {
    return this.AllProducts.filter(product => {
      const term = text.toLowerCase();
        return product.name.toLowerCase().includes(term)
        || product.tagName.toLowerCase().includes(term);
    });
  }

  public SearchUpdateData() {
    this.products$ = this.filter.valueChanges.pipe(
      startWith(''),
      map(text => this.Search(text, this.pipe)) );
  }

  onRestoreProduct(product: IProduct) {
    let dialogData;
    this.translate.get('confirm_restore').subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.blockUI.start();
        let toastrData;
        const apiDeleteProduct: ApiDeleteProduct =  {
          locationID: product.location.id,
          productID: product.id
        };
        this.service.restoreProduct(apiDeleteProduct).subscribe(
          (res: any) => {
            this.translate.get('product_restored_success').subscribe(value => { toastrData = value; } );
            this.productService.restoreProductFromTrash(product);
            this.productService.loadAll();
            this.toastr.success(toastrData);
            this.refreshNotificationList();
            this.blockUI.stop();
          },
          (err) => {
            this.blockUI.stop();
            console.log('Error:' + err);
          }
        );
      }
    });
  }

  onRestoreSelectedProducts() {
    const selectedDeletedProducts = this.productService.selectedDeletedProducts();
    let dialogData;
    this.translate.get('confirm_restore').subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.blockUI.start();
        const apiProductsToDelete = selectedDeletedProducts.map( x => {
          return <ApiDeleteProduct>
          {
              productID : x.id,
              locationID : x.location.id
          };
        });
        let toastrData;
        this.service.restoreSelectedProducts(apiProductsToDelete).subscribe(
          (res: any) => {
              this.translate.get('product_restored_success').subscribe(value => { toastrData = value; } );
              this.productService.restoreSelectedProductsFromTrash(selectedDeletedProducts);
              this.productService.loadAll();
              this.toastr.success(toastrData);
              this.refreshNotificationList();
              this.blockUI.stop();
            },
          (err) => {
            this.blockUI.stop();
            console.log('Error:' + err);
          }
        );
      }
    });
  }

  onPermanentDelete(product: IProduct) {
    let dialogData;
    this.translate.get('confirm_delete_trash').subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.blockUI.start();
        let toastrData;
        this.service.deletePermanentProduct(product.id).subscribe(
          (res: any) => {
              this.service.LoadUserProducts();
              this.translate.get('product_delete_success').subscribe(value => { toastrData = value; } );
              this.productService.loadAll();
              this.toastr.success(toastrData);
              this.refreshNotificationList();
              this.blockUI.stop();
            },
            (err) => {
              this.blockUI.stop();
              console.log('Error:' + err);
            }
          );
        }
      });
  }

  onPermanentDeleteSelectedProducts() {
      const selectedDeletedProducts = this.productService.selectedDeletedProducts();
      const selectedDeletedProductsIDs = [];
      selectedDeletedProducts.forEach(p => {
      selectedDeletedProductsIDs.push(p.id);
      });
      let dialogData;
      this.translate.get('confirm_delete_trash').subscribe(value => { dialogData = value; } );
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.blockUI.start();
          let toastrData;
          this.service.deletePermanentaSelectedProducts(selectedDeletedProductsIDs).subscribe(
            (res: any) => {
              this.translate.get('product_delete_success').subscribe(value => { toastrData = value; } );
              this.toastr.success(toastrData);
              this.productService.loadAll();
              this.selectedProducts = this.productService.selectedProductsCount();
              this.refreshNotificationList();
              this.blockUI.stop();
            },
            (err) => {
              this.blockUI.stop();
              console.log('Error:' + err);
            }
          );
          }
        });
  }

  onPermanentDeleteAllProducts() {
      const allProductsInTrash = this.productService.productsInTrash();
      const allProductsInTrashIDs = [];
      allProductsInTrash.forEach(p => {
        allProductsInTrashIDs.push(p.id);
      });
      let dialogData;
      this.translate.get('confirm_delete_all').subscribe(value => { dialogData = value; } );
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.blockUI.start();
          let toastrData;
          this.service.deletePermanentaAllProducts(allProductsInTrashIDs).subscribe(
            (res: any) => {
              this.translate.get('product_delete_success').subscribe(value => { toastrData = value; } );
              this.service.LoadUserProducts();
              this.productService.loadAll();
              this.toastr.success(toastrData);
              this.refreshNotificationList();
              this.blockUI.stop();
            },
            (err) => {
              this.blockUI.stop();
              console.log('Error:' + err);
            }
          );
        }
      });
  }

  changeAllCheckboxs(event: any) {
      if (event === 'A') {
        this.productService.checkAllDeletedSelectedProducts();
      } else {
        this.productService.cleanDeletedSelectedProducts();
      }
      this.selectedProducts = this.productService.selectedDeletedProductsCount();
  }
  onSearchKey() {
    this.goToFirstPagination();
  }

  goToFirstPagination() {
    this.p = 1;
  }
  onRowClick(event, product: IProduct) {
    if (event.ctrlKey) {
      if (product.selected){
        product.selected = false;
      } else {
        product.selected = true;
      }
    }
    this.CountSelectedDeletedProducts();
  }
  CountSelectedDeletedProducts() {
    this.selectedProducts = this.productService.selectedDeletedProductsCount();
  }
  refreshNotificationList() {
    this.notificationService.loadAll();
  }
}
