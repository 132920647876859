import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AdminProductsModel } from 'src/model/adminApiTypes';
import { AppConfig } from 'src/app/app.config';
import { IProduct } from '../../../model/apitypes';
import { InfoProductComponent } from 'src/app/dialogs/info-product/info-product.component';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-product-admin',
  templateUrl: './product-admin.component.html',
  styleUrls: ['./product-admin.component.scss']
})
export class ProductAdminComponent implements OnInit {

  products$: Observable<AdminProductsModel[]>;
  products: AdminProductsModel[];
  sortBy = '';
  sortAsc = true;
  sort_str = '';
  searchText = '';
  k: any;
  isShown: boolean = false ;

  BaseURI = AppConfig.settings.API_Address;

  constructor(public service: AdministratorService,
    public translate: TranslateService,
    public userService: UserService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.loadProducts();
    if (window.screen.width <= 884) {
      this.isShown = true;
    }
  }

  loadProducts(){
    this.service.getProducts().subscribe(
      (res: any) => {
        this.products$ = res;
        console.log(res);
      },
      err => {
        console.log(err);
      },
    );
  }

  onSort(sort_by: any) {
    this.sortBy = sort_by;
    if (this.sortAsc) {
      this.sort_str = sort_by + ' asc';
      this.sortAsc = false;
    } else {
      this.sort_str = sort_by + ' desc';
      this.sortAsc = true;
    }
  }

  onInfoProduct(product: IProduct) {
    this.userService.setProduct(product);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.height = 'auto';

    const ref =  this.dialog.open(InfoProductComponent, dialogConfig);
  }

  onSearchKey() {
    this.goToFirstPagination();
  }

  goToFirstPagination() {
    this.k = 1;
  }

}
