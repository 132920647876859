import { Component, OnInit, ViewChild } from '@angular/core';
import { IProduct, CSVRecord,  ILocation,  ApiDeleteProduct, CsvExportProductList, Category} from '../../../model/apitypes';
import { DecimalPipe} from '@angular/common';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { AppStateService } from '../../app-state.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProductComponent } from '../../dialogs/add-product/add-product.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../services/product/product.service';
import * as _ from 'lodash';
import { ImportProducutComponent } from '../../dialogs/import-producut/import-producut.component';
import { InfoProductComponent } from '../../dialogs/info-product/info-product.component';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '../../app.config';
import { MoveProductComponent } from 'src/app/dialogs/move-product/move-product.component';
import { LocationService } from 'src/app/services/location/location.service';
import { TableSettingsComponent } from 'src/app/dialogs/table-settings/table-settings.component';
import { StoreService } from 'src/app/services/store/store.service';
import { ExportToCsv } from 'export-to-csv';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ManageCategoriesComponent } from 'src/app/dialogs/manage-categories/manage-categories.component';
import { CategoryService } from 'src/app/services/category/category.service';
import {ExcelService} from '../../services/excel.service';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.css']
})


export class SearchComponent implements OnInit {

  products$: Observable<IProduct[]> ;
  products: IProduct[];
  filter = new FormControl('');


  BaseURI = AppConfig.settings.API_Address;

  searchText = '';
  selectedProducts = 0;
  selectedLocactionToMoveItems: number;

  sort_str = 'name';
  sortBy = '';
  sortAsc = true;
  page = 1;
  pageSize = 50;
  collectionSize = 0;
  fileToUpload: File;
  url = '';
  files: Array<any> = new Array<any>();
  p: any;
  isChecked = '';
  public records: any[] = [];
  isShown: boolean = false ;
  private productLoadSubscription: any;

  @ViewChild('csvReader', { read: true, static: false })  csvReader: any;
  filterText: '';
  @BlockUI() blockUI: NgBlockUI;

  constructor( public pipe: DecimalPipe,
    public service: UserService,
    public locationService: LocationService,
    public categoryService: CategoryService,
    public appState: AppStateService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    public productService: ProductService,
    public translate: TranslateService,
    public notificationService: NotificationService,
    public storeService: StoreService,
    public excelService:ExcelService) {
  }

  ngOnInit() {
    
      if (!this.productService.ProductsLoadedValue) {
        this.productService.loadAll();
      }

    this.refreshProductList();
    if (window.screen.width <= 884) {
      this.isShown = true;
    }
    this.productService.cleanSelectedProducts();
    this.appState.currentPage = 'Products';
    this.productService.ProductsLoaded.subscribe(loaded => {
      if (loaded) {
        this.reloadFilters();
      }
    });

  }

  /* Location filter */
  onLocationClick(l: ILocation) {
    if (l !== this.appState.selectedLocation ) {
      this.appState.selectedCategory = null;
      this.appState.selectedLocation = l;
      if (!l) {
        // tslint:disable-next-line: max-line-length
        this.productService.SearchProductsForSelectedLocationAndCategory(0, this.appState.selectedCategory ? this.appState.selectedCategory.name : null);
        this.productService.createCategoriesListForLocation(null);
        return;
      }
      // tslint:disable-next-line: max-line-length
      this.productService.SearchProductsForSelectedLocationAndCategory(this.appState.selectedLocation.id, this.appState.selectedCategory ? this.appState.selectedCategory.name : null);
      this.productService.createCategoriesListForLocation(l.name);
      this.goToFirstPagination();
    }
  }

  /* Categories filter */
  onCategoriesClick(c: Category) {
    if (c) {
      this.goToFirstPagination();
      this.appState.selectedCategory = c;
      if (!this.appState.selectedLocation) {
        this.productService.SearchProductsForSelectedLocationAndCategory(0, this.appState.selectedCategory.name);
        return;
      }
      // tslint:disable-next-line: max-line-length
      this.productService.SearchProductsForSelectedLocationAndCategory(this.appState.selectedLocation.id, this.appState.selectedCategory.name);
    } else {
      this.appState.selectedCategory = null;

      if (!this.appState.selectedLocation) {
        // tslint:disable-next-line: max-line-length
        this.productService.SearchProductsForSelectedLocationAndCategory(0, this.appState.selectedCategory ? this.appState.selectedCategory.name : null);
        return;
      }
      // tslint:disable-next-line: max-line-length
      this.productService.SearchProductsForSelectedLocationAndCategory(this.appState.selectedLocation.id, this.appState.selectedCategory ? this.appState.selectedCategory.name : null);
    }
  }
  onManageCategories() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.minWidth = '20%';
    const ref =  this.dialog.open(ManageCategoriesComponent, dialogConfig);
    // ref.afterClosed().subscribe(() => {
    //   console.log('closed');
    // });
  }

  /* Add new product */
  onAddProduct() {
    this.service.setProduct(null);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (window.screen.width <= 884) {
      dialogConfig.width = '90%';
    }
    else{
      dialogConfig.width = '60%';
    }
    const ref =  this.dialog.open(ProductComponent, dialogConfig);
    ref.afterClosed().subscribe(isUpload => {
      if (isUpload) {
        this.refreshDashboard();
        this.productService.loadAll();
      }
    });
  }

  /* Export / Import products to/from CSV file */
  onPrintAllProductsToCSV() {
        console.log("onPrintAllProductsToCSV")
    this.exportToCsv(this.service.AllProducts);
  }

    /* Export / Import products to/from Xlsx file */
    onPrintAllProductsToXlsx() {
      console.log("onPrintAllProductsToXLSX")
      this.exportToXlsx(this.productService.selectedProducts());
    }

  onUploadProductsListener() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.height = 'auto';

    const ref =  this.dialog.open(ImportProducutComponent, dialogConfig);
    ref.afterClosed().subscribe(() => {
      this.service.LoadUserProducts();
    });
  }

  /* Selection operations: Clear, Delete, Export CSV, Move */
  onCleanSelection() {
    this.productService.cleanSelectedProducts();
    this.selectedProducts = this.productService.selectedProductsCount();
  }
  onDeleteSelectedProducts() {
    let dialogData;
    this.translate.get('confirm_delete_product').subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.blockUI.start();
        this.productService.deleteSelectedProducts().then(res => {
          let toastrData;
          if (res) {
            this.translate.get('product_delete_success').subscribe(value => { toastrData = value; } );
            this.toastr.success(toastrData);
            this.productService.loadAll();
            this.selectedProducts = this.productService.selectedProductsCount();
            this.appState.clearSelectedFilters();
            this.reloadFilters();
            this.refreshDashboard();
            this.blockUI.stop();
          } else {
            this.translate.get('product_delete_error').subscribe(value => { toastrData = value; } );
            this.toastr.error(toastrData);
            this.blockUI.stop();
          }
        });
        }
      });
  }

  onPrintSelectedProductsToCSV() {
    this.exportToCsv(this.productService.selectedProducts());
  }

  onPrintSelectedProductsToXlsx() {
    this.exportToXlsx(this.productService.selectedProducts());
  }

  onMoveSelectedProductsToLocation(l: ILocation) {
    this.appState.selectedLocation = null;
    this.blockUI.start();
    this.productService.moveSelectedItemsToLocation(l.id).then(result => {
      let toastrData;
      if (result) {
        this.translate.get('product_moved_success').subscribe(value => { toastrData = value; } );
        this.toastr.success(toastrData);
        this.selectedProducts = this.productService.selectedProductsCount();
        this.refreshDashboard();
        this.productService.loadAll();
        this.blockUI.stop();
      } else {
        this.translate.get('product_moved_error').subscribe(value => { toastrData = value; } );
        this.toastr.warning(toastrData);
        this.blockUI.stop();
      }
    });
  }

  /* Table settings */
  onShowTableSettings() {
    this.dialog.open(TableSettingsComponent, {
      width: '20%',
      height: 'auto'
    });
  }

  /* Product operations: Move, Update, Delete, Info */
  onMoveProduct(product: IProduct) {
    const dialogRef = this.dialog.open(MoveProductComponent, {
      width: 'auto',
      minWidth: '50%',
      data: product
    });
    dialogRef.afterClosed().subscribe(isSaved => {
      if (isSaved) {
        this.refreshDashboard();
        this.productService.loadAll();
      }
    });
  }
  onUpdateProduct(product: IProduct) {
    this.service.setProduct(product);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (window.screen.width <= 884) {
      dialogConfig.width = '90%';
    }
    else{
      dialogConfig.width = '60%';
    }
    const ref =  this.dialog.open(ProductComponent, dialogConfig);
    ref.afterClosed().subscribe(isUpload => {
      if (isUpload) {
      this.reloadFilters();
      this.refreshDashboard();
      this.productService.loadAll();
      }
    });
  }
  onInfoProduct(product: IProduct) {
    this.service.setProduct(product);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.height = 'auto';

    const ref =  this.dialog.open(InfoProductComponent, dialogConfig);
  }
  onDeleteProduct(product: IProduct) {
    let dialogData;
    this.translate.get('confirm_delete_product').subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.blockUI.start();
        let toastrData;
        const apiDeleteProduct: ApiDeleteProduct =  {
          locationID: product.location.id,
          productID: product.id
        };

        this.service.deleteProduct(apiDeleteProduct).subscribe(
          (res: any) => {
              this.translate.get('product_delete_success').subscribe(value => { toastrData = value; } );
              this.productService.deleteProduct(res);
              this.toastr.success(toastrData);
              this.selectedProducts = this.productService.selectedProductsCount();
              this.reloadFilters();
              this.productService.loadAll();
              this.refreshDashboard();
              this.blockUI.stop();
            },
          (err) => {
            console.log('Error:' + err);
            this.blockUI.stop();
          }
        );
        }
      });
  }

  onRowClick(event, product: IProduct) {
    if (event.ctrlKey) {
      if (product.selected) {
        product.selected = false;
      } else {
        product.selected = true;
      }
    }
    this.countSelectedProducts();
  }
  onSort(sort_by: any) {
    this.sortBy = sort_by;
    if (this.sortAsc) {
      this.sort_str = sort_by + ' asc';
      this.sortAsc = false;
    } else {
      this.sort_str = sort_by + ' desc';
      this.sortAsc = true;
    }
  }
  onSearchKey() {
    this.goToFirstPagination();
  }
  goToFirstPagination() {
    this.p = 1;
  }
  changeAllCheckboxs(event: any) {
    if (event === 'A') {
      this.productService.checkAllProducts();
    } else {
      this.productService.cleanSelectedProducts();
    }
    this.selectedProducts = this.productService.selectedProductsCount();
  }

   /* Xlsx export methods */
  exportToXlsx(productList: IProduct[]) {
    // Create header
    let name; this.translate.get('name') .subscribe(v => { name = v; });
    let category; this.translate.get('category') .subscribe(v => { category = v; });
    let tag_name; this.translate.get('tag_name') .subscribe(v => { tag_name = v; });
    let tag_epc; this.translate.get('tag_epc') .subscribe(v => { tag_epc = v; });
    let count; this.translate.get('count') .subscribe(v => { count = v; });
    let expiration_date; this.translate.get('expiration_date') .subscribe(v => { expiration_date = v; });
    let description; this.translate.get('description') .subscribe(v => { description = v; });
    let product_group; this.translate.get('product_group') .subscribe(v => { product_group = v; });
    let location; this.translate.get('location') .subscribe(v => { location = v; });
    const header: string[] = [name, category, tag_name, tag_epc, count, expiration_date, description, product_group, location];
    let defaultCategory; this.translate.get('default_category') .subscribe(v => { defaultCategory = v; });
    let defaultLocation; this.translate.get('default_location') .subscribe(v => { defaultLocation = v; });
    // Map product list to print
    const printProductList: CsvExportProductList[] = [];
    productList.forEach(p => {
      printProductList.push({'name': p.name ? p.name : '',
                             // tslint:disable-next-line: max-line-length
                             'category': p.productCategory.name ? (p.productCategory.defaultCollection ? defaultCategory : p.productCategory.name) : '' ,
                             'tagName': p.tagName ? p.tagName : '',
                             'tagEPC': p.epcs ? p.epcs.toString() + ';' : '',
                             'count': p.count ? p.count : null,
                             'expirationDate': p.expirationDate ? p.expirationDate.toString().split('T', 1)[0] : '',
                             'description': p.description ? p.description : '',
                             'productGroupName': p.productGroup ? p.productGroup.name : '' ,
                             'location': p.location.name ? (p.location.unknownCollection ? defaultLocation : p.location.name) : '' ,
                            });
    });
    // Sortowanie alfabetycznie po nazwie
    printProductList.sort(function(a, b) {
      const A = a.name.toLowerCase();
      const B = b.name.toLowerCase();
      return (A < B) ? -1 : (A > B) ? 1 : 0;
    });

      this.excelService.exportAsExcelFile(printProductList, 'ProductList');
  }

  /* CSV export methods */
  exportToCsv(productList: IProduct[]) {
    // Create header
    let name; this.translate.get('name') .subscribe(v => { name = v; });
    let count; this.translate.get('count') .subscribe(v => { count = v; });
    let category; this.translate.get('category') .subscribe(v => { category = v; });
    let tag_name; this.translate.get('tag_name') .subscribe(v => { tag_name = v; });
    let tag_epc; this.translate.get('tag_epc') .subscribe(v => { tag_epc = v; });
    let location; this.translate.get('location') .subscribe(v => { location = v; });
    let description; this.translate.get('description') .subscribe(v => { description = v; });
    let expiration_date; this.translate.get('expiration_date') .subscribe(v => { expiration_date = v; });
    let product_group; this.translate.get('product_group') .subscribe(v => { product_group = v; });
    const header: string[] = [name, category, count, description, location, tag_epc, tag_name,  expiration_date, product_group];
    let defaultCategory; this.translate.get('default_category') .subscribe(v => { defaultCategory = v; });
    let defaultLocation; this.translate.get('default_location') .subscribe(v => { defaultLocation = v; });
    // Map product list to print
    const printProductList: CsvExportProductList[] = [];
    productList.forEach(p => {
      printProductList.push({'name': p.name ? p.name : '',
                             // tslint:disable-next-line: max-line-length
                             'category': p.productCategory.name ? (p.productCategory.defaultCollection ? defaultCategory : p.productCategory.name) : '' ,
                             'count': p.count ? p.count : null,
                             'description': p.description ? p.description : '',
                             'location': p.location.name ? (p.location.unknownCollection ? defaultLocation : p.location.name) : '' ,
                             'tagEPC': p.epcs ? p.epcs.toString() + ';' : '',
                             'tagName': p.tagName ? p.tagName : '',
                             'expirationDate': p.expirationDate ? p.expirationDate.toString().split('T', 1)[0] : '',
                             'productGroupName': p.productGroup ? p.productGroup.name : '' ,
                            });
    });
    // Sortowanie alfabetycznie po nazwie
    printProductList.sort(function(a, b) {
      const A = a.name.toLowerCase();
      const B = b.name.toLowerCase();
      return (A < B) ? -1 : (A > B) ? 1 : 0;
    });
    const CSVoptions = {
      filename: this.appState.userName + '_products.csv',
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Product List',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: header
    };

    const csvExporter = new ExportToCsv(CSVoptions);
    csvExporter.generateCsv(printProductList);
  }

  /* CSV import methods */
  uploadListener($event: any): void {
    const text = [];
    const files = $event.srcElement.files;
    console.log(files);
    if (files.length > 0) {
      if (this.isValidCSVFile(files[0])) {

        const input = $event.target;
        const reader = new FileReader();
        reader.readAsText(input.files[0]);

        reader.onload = () => {
          const csvData = reader.result;
          const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

          const headersRow = this.getHeaderArray(csvRecordsArray);
          const headers = ['name', 'location', 'tagName', 'tagEPC'];

          if (headersRow.length === 4) {
            for (const entry of headers) {
              const isNull = headersRow.find(x => x === entry);
              if (isNull == null) {
                alert('The file is not proerly prepared, please check the headers.');
                return;
              }
            }
          } else {
            alert('The file is not proerly prepared, please check the headers.');
            return;
          }

          this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
          console.log(this.records);
        };

        reader.onerror = function () {
          console.log('error is occured while reading file!');
        };

      } else {
        alert('Please import valid .csv file.');
        this.fileReset();
      }
    }
  }
  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const csvArr = [];
    console.log(csvRecordsArray);
    for (let i = 1; i < csvRecordsArray.length; i++) {
      const curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length === headerLength) {
        const csvRecord: CSVRecord = new CSVRecord();
        csvRecord.name = curruntRecord[0].trim();
        csvRecord.location = curruntRecord[1].trim();
        csvRecord.tagName = curruntRecord[2].trim();
        csvRecord.tagEPC = curruntRecord[3].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }
  getHeaderArray(csvRecordsArr: any) {
    const headers = (<string>csvRecordsArr[0]).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }
  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
  }

 /* Others */
  reloadFilters() {
    this.onLocationClick(this.appState.selectedLocation);
    this.onCategoriesClick(this.appState.selectedCategory);
  }
  countSelectedProducts() {
    this.selectedProducts = this.productService.selectedProductsCount();
  }
  refreshProductList() {
    this.products$ = this.productService.products;
  }
  refreshNotificationList() {
    this.notificationService.loadAll();
  }
  refreshDashboardLocations() {
    this.locationService.getLocationsGeneralInfo();
  }
  refreshDashboard() {
    this.refreshNotificationList();
    this.refreshDashboardLocations();
  }

  onSelectFile(files: FileList) {

    if (files.length === 0) {
      return;
    }
    this.fileToUpload = files.item(0);

    const fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(this.fileToUpload);

    fileReader.onload = (event: any) => {
      this.url = event.target.result;
    };

    this.files.push({ data: this.fileToUpload, fileName: this.fileToUpload.name });

    const fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);


    const file = this.files[0].data;
    this.service.uploadProductPicture(formData)
      .subscribe((result: string) => {
    });
  }

}

