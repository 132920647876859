import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { UserService } from '../../services/user/user.service';
import { CSVRecord, ILocation, CsvTemplateHeaders } from 'src/model/apitypes';
import { AppStateService } from '../../app-state.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { ProductService } from '../../services/product/product.service';
import { ToastrService } from 'ngx-toastr';
import { SearchComponent } from '../../pages/product-search/product-search.component';
import { LocationService } from 'src/app/services/location/location.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-import-producut',
  templateUrl: './import-producut.component.html',
  styleUrls: ['./import-producut.component.scss']
})
export class ImportProducutComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public dialogRef: MatDialogRef<ImportProducutComponent>,
    private service: UserService,
    public State: AppStateService,
    public translate: TranslateService,
    private productService: ProductService,
    private toastr: ToastrService,
    public locationService: LocationService) { }

  ngOnInit() {
    //this.buttonUpload = "Submit";
    //this.translate.get('upload_instruction').subscribe(value => { this.successMsg = value; } );
    this.translate.get('submit').subscribe(value => { this.buttonUpload = value; } );
    this.service.getUserLocations().subscribe(
      (res: ILocation[]) => {
        this.locations = res;
      },
      err => {
        console.log(err);
      },
    );
  }

  public locationSelected: boolean = false;
  selectedLocationName:string;
  buttonUpload: string;
  locations: ILocation[];
  fileToUpload: File;
  selectedLoactionToMoveItems: number;
  errorMsg: string;
  successMsg: string;
  showError: boolean;
  showSuccess: boolean;
  locationId: number;
  public records: any[] = [];
  csvTemplateHeaders: CsvTemplateHeaders[] = [{name: 'Fire Extinguisher', category: 'Safety', tagName: 'M_01', tagEPC: 'FFFFFFFF', count: 100, expirationDate: '2019-11-15', description: 'Red'}];


  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.translate.get('submit').subscribe(value => { this.buttonUpload = value; } );
      if (this.isValidExcelFile(element)) {
        this.fileToUpload = element;
        this.showError = false;
      } else {
        this.translate.get('upload_instruction').subscribe(value => { this.successMsg = value; } );
        this.translate.get('error_msg_file_format').subscribe(value => { this.errorMsg = value; } );
        this.showError = true;
        this.showSuccess = false;
        this.fileToUpload = null;
      }
    }
  }

  locationChange(event: any){
    console.log(event);
    this.selectedLocationName = event.name;
    this.locationSelected = true;
    this.locationId = event.id;
  }

  onSubmit() {
    if (this.fileToUpload) {
      if (!this.locationSelected) {
        this.translate.get('error_msg_location').subscribe(value => { this.errorMsg = value; } );
        this.showError = true;
        this.showSuccess = false;
        return;
      }
      this.blockUI.start();
      this.showError = false;
      let formData: FormData = new FormData();
      formData.append('formFile', this.fileToUpload);
      formData.append('locationId', this.locationId.toString());
      this.State.Working = true;
      this.service.uploadProductsForImport(formData).subscribe(
        (res: any) => {
          let toastrData;
          this.showError = false;
          this.showSuccess = true;
          this.successMsg = res.msg;
          this.State.Working = false;
          this.productService.loadAll();
          this.State.clearSelectedFilters();
          if (res.code === 1) {
            this.translate.get('data_import_success').subscribe(value => { toastrData = value; } );
            this.toastr.success( toastrData, this.successMsg );
          }
          if (res.code === 2) {
            this.translate.get('data_import_nothing').subscribe(value => { toastrData = value; } );
            this.toastr.success( toastrData, this.successMsg );
          }
          if (res.code === 0) {
            this.translate.get('data_import_warning').subscribe(value => { toastrData = value; } );
            this.toastr.warning( toastrData, this.successMsg );
          }
          this.onClose();
        },
        err => {
          this.showError = true;
          this.showSuccess = false;
          this.errorMsg = err;
          this.State.Working = false;
          this.translate.get('error').subscribe(value => { this.buttonUpload = value; } );
          this.onClose();
        },
      );
    } else {
      this.translate.get('error_msg_file_upload').subscribe(value => { this.errorMsg = value; } );
      this.showError = true;
      this.showSuccess = false;
      this.fileToUpload = null;
    }
  }

  moveToSelectedLocation(event: any) {
    this.selectedLoactionToMoveItems = event.target.value;
  }

  deleteAttachment() {
    this.fileToUpload = null;
  }

  onClose() {
     this.dialogRef.close();
     this.blockUI.stop();
   }

   isValidExcelFile(file: any) {
    return file.name.endsWith('.xlsx');
  }

   isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  uploadListener(): void {
    if(this.fileToUpload) {
        let reader = new FileReader();
        reader.readAsText(this.fileToUpload);

        reader.onload = () => {
          let csvData = reader.result;
          let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

          let headersRow = this.getHeaderArray(csvRecordsArray);
          let headers = ["name", "tagName", "tagEPC", "tagPassword", "tagTID", "TagUserData", "code", "count", "price", "category"];

          if(headersRow.length == 4){
            for (let entry of headers){
              let isNull = headersRow.find(x => x == entry);
              if(isNull == null){
                alert("The file is not proerly prepared, please check the headers.");
                return;
              }
            }
          }
          else {
            alert("The file is not proerly prepared, please check the headers.");
            return;
          }

          this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
          console.log(this.records);
        };

        reader.onerror = function () {
          console.log('error is occured while reading file!');
        };

      } else {
        alert("Please import valid .csv file.");
      }
    }

    getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
      let csvArr = [];
      console.log(csvRecordsArray);
      for (let i = 1; i < csvRecordsArray.length; i++) {
        let curruntRecord = (<string>csvRecordsArray[i]).split(',');
        if (curruntRecord.length == headerLength) {
          let csvRecord: CSVRecord = new CSVRecord();
          csvRecord.name = curruntRecord[0].trim();
          csvRecord.location = curruntRecord[1].trim();
          csvRecord.tagName = curruntRecord[2].trim();
          csvRecord.tagEPC = curruntRecord[3].trim();
          csvArr.push(csvRecord);
        }
      }
      return csvArr;
    }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  downloadTemplateCsv() {
    this.service.downloadExcelTemplate().subscribe(
      res => {
        const blob = new Blob([res], { type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const file = new File([blob], 'products_import_template' + '.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(file);
      },
      res => {
        console.log(res);
      }
  );
  }

}
