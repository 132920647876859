import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/app-state.service';

@Component({
  selector: 'app-aboutuser',
  templateUrl: './aboutuser.component.html',
  styleUrls: ['./aboutuser.component.scss']
})
export class AboutuserComponent implements OnInit {

  constructor(public appState: AppStateService) { }

    isFinish: boolean = false ;
    isZero: boolean = false ;
    licenseIsFinish: boolean = false ;
    isNoLimit: boolean = false;

    ngOnInit() {
      if(this.appState.finish > 1500){
        this.isNoLimit = true;
      }

      if(this.appState.finish <= 30 && this.appState.finish > 0){
        this.isFinish = true;
        this.isNoLimit = false;
    }

    if(this.appState.finish == 0){
      this.isZero = true;
      this.isFinish = false;
      this.isNoLimit = false;
    }

    if(this.appState.finish < 0){
      this.isZero = false;
      this.isFinish = false;
      this.licenseIsFinish = true;
      this.isNoLimit = false;
    }
  }
}
