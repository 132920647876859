import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { LicenseUserDialogComponent } from 'src/app/dialogs/administrator/license-user-dialog/license-user-dialog.component';
import { AdministratorUserModel, AdministratorUserPassword } from 'src/model/adminApiTypes';
import { ChangePasswordDialogComponent } from 'src/app/dialogs/administrator/change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styleUrls: ['./users-admin.component.scss']
})
export class UsersAdminComponent implements OnInit {

  users$: Observable<AdministratorUserModel[]>;
  users: AdministratorUserModel[];
  sortBy = '';
  sortAsc = true;
  sort_str = 'name';
  searchText = '';
  isShown: boolean = false ;

  constructor(public service: AdministratorService,
    public translate: TranslateService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.loadUsers();
    if (window.screen.width <= 884) {
      this.isShown = true;
    }
  }

  loadUsers(){
    this.service.getUsers().subscribe(
      (res: any) => {
        this.users$ = res;
        console.log(res);
      },
      err => {
        console.log(err);
      },
    );
  }

  onActivateUserAccount(userModel: AdministratorUserModel){
    this.service.activateUserAccount(userModel).subscribe(
      (res: any) => {
        this.loadUsers();
        console.log(res);
      },
      err => {
        console.log(err);
      },
    );
  }

  onCreateSetPasswordDialog(userModel: AdministratorUserModel){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (window.screen.width <= 884) {
      dialogConfig.width = '90%';
    }
    else{
    dialogConfig.width = '30%';
    }
    let model: AdministratorUserPassword = {
      userId: userModel.id,
      newPassword: ""
    };
    dialogConfig.data = model;
    const dialogRef = this.dialog.open(
      ChangePasswordDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Success") {
        this.loadUsers();
      }
    });
  }

  onCreateLicenseDialog(userModel: AdministratorUserModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (window.screen.width <= 884) {
      dialogConfig.width = '90%';
    }
    else{
    dialogConfig.width = '30%';
    }
    dialogConfig.data = userModel;
    const dialogRef = this.dialog.open(
      LicenseUserDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Success") {
        this.loadUsers();
      }
    });
  }

  onSort(sort_by: any) {
    this.sortBy = sort_by;
    if (this.sortAsc) {
      this.sort_str = sort_by + ' asc';
      this.sortAsc = false;
    } else {
      this.sort_str = sort_by + ' desc';
      this.sortAsc = true;
    }
  }

}
