import { UserService } from './../../services/user/user.service';
import { Component, OnInit, PipeTransform, ɵConsole, ElementRef, ViewChild, NgModule, Directive } from '@angular/core';
import { AppStateService } from '../../app-state.service';
import { InventoryReport, InventoryProduct, CsvExportReport, ReportAllGroupViewModel, ReportGroupViewModel } from 'src/model/apitypes';
import { DecimalPipe, DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatDialogConfig, MatDialog, MatSort } from '@angular/material';
import { ReportComponent } from '../../dialogs/report/report.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import 'jspdf-autotable';
import { ExportToCsv } from 'export-to-csv';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ReportService } from 'src/app/services/report/report.service';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store/store.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LocationService } from 'src/app/services/location/location.service';

@Component({
  selector: 'app-report-search',
  templateUrl: './report-search.component.html',
  styleUrls: ['./report-search.component.scss'],
  providers: [DatePipe],
})

export class ReportSearchComponent implements OnInit {

  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  private reportsLoadSubscription: any;
  searchText = '';

  booleanValue: any = false;
  sortBy = '';
  sortAsc = false;
  sort_str = 'location';

  @BlockUI() blockUI: NgBlockUI;

  constructor(public pipe: DecimalPipe,
    public datePipe: DatePipe,
    public appState: AppStateService,
    public service: UserService,
    private dialog: MatDialog,
    public translate: TranslateService,
    public calendar: NgbCalendar,
    public reportService: ReportService,
    private toastr: ToastrService,
    private storeService: StoreService,
    public locationService: LocationService,
    public userService: UserService) {

    }

    filter = new FormControl('');
    filterText: '';
    isShown: boolean = false ;

    reportAllGroups: ReportAllGroupViewModel[];
    inventoryID: number;
    groupModel: ReportGroupViewModel[];
    

    ngOnInit() {
      this.appState.currentPage = 'Reports';
      if (window.screen.width <= 884) {
        this.isShown = true;
      }     
      this.loadInitialFiterDate();
      //this.reportService.FilterReportsByDate(this.fromDate, this.toDate);
      if (!this.reportService.reportsLoaded) {  // Czy raporty są już załadowane
        this.reportService.TakeReportsByDate(this.fromDate, this.toDate);
      }
      this.sortColumn('dateTime');    
    }

  // ngOnInit() {
  //   this.appState.currentPage = 'Reports';
  //   if (window.screen.width <= 884) {
  //     this.isShown = true;
  //   }
  //     if (this.reportService.reportsLoaded) {  // Czy raporty są już załadowane
  //       this.loadInitialFiterDate();
  //       this.reportService.FilterReportsByDate(this.fromDate, this.toDate);
  //       this.sortColumn('dateTime');

  //     } else {
  //       this.reportsLoadSubscription = this.reportService.ReportsLoaded.subscribe(loaded => {
  //         if (loaded) {
  //           this.loadInitialFiterDate();
  //           this.reportService.FilterReportsByDate(this.fromDate, this.toDate);
  //           this.sortColumn('dateTime');
  //         }
  //       });
  //     }
  // }

  sortColumn(sort_by: any) {

    if(sort_by=='location'){
      console.log("location");

      this.sortBy = sort_by;
      this.sort_str = sort_by;

      if (this.sortAsc) {
        this.sort_str = sort_by + ' asc';
        this.sortAsc = false;
      } else {
        this.sort_str = sort_by + ' desc';
        this.sortAsc = true;
      }
    }
    else if(sort_by=='dateTime'){
      console.log("dateTime");

      this.sortBy = sort_by;
      this.sort_str = sort_by;

      if (this.sortAsc) {
        this.sort_str = sort_by + ' asc';
        this.sortAsc = false;
      } else {
        this.sort_str = sort_by + ' desc';
        this.sortAsc = true;
      }
    }
  }


  loadInitialFiterDate() {
    if (this.storeService.reportFilterFromDate || this.storeService.reportFilterToDate) {
      this.toDate = this.storeService.reportFilterToDate;
      this.fromDate = this.storeService.reportFilterFromDate;
      //this.reportService.FilterReportsByDate(this.fromDate, this.toDate);
      this.reportService.TakeReportsByDate(this.fromDate, this.toDate);
    } else {
      this.toDate = this.calendar.getToday();
      this.fromDate = this.calendar.getNext(this.calendar.getToday() , 'd', -30);
    }
  }

  selectReport(data: number){
      //console.log(JSON.stringify(this.reportService.reportsGroupStore.reportsGroups.filter(x => x.inventoryReportID === data)));
      return this.reportService.reportsGroupStore.reportsGroups.filter(x => x.inventoryReportID === data);
  }

  onViewReport(data: InventoryReport) {
    this.service.selectedReport = data;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (window.screen.width <= 884) {
      dialogConfig.width = '90%';
    }
    else{
      dialogConfig.width = '50%';
      dialogConfig.height = '87%';
    }
    this.dialog.open(ReportComponent, dialogConfig);
  }
   
  async onPrintPDFReport(data: InventoryReport) {

    let title;
    this.translate.get('inventory_report').subscribe(value => { title = value; } );

    this.reportAllGroups = this.selectReport(data.id);

   this.reportAllGroups.map(x => {
      this.inventoryID = x.inventoryReportID,
      this.groupModel = x.reportModel
    });

    const date = new Date(data.dateTime).toLocaleDateString().toString();
    const time = new Date(data.dateTime).toLocaleTimeString().toString();
    const reportTime = date + ' ' + time;

    let report_status;
    this.translate.get('raport_state') .subscribe(value => { report_status = value; } );
    let product;
    this.translate.get('product') .subscribe(value => { product = value; } );
    let epc;
    this.translate.get('epc') .subscribe(value => { epc = value; } );
    let description;
    this.translate.get('description') .subscribe(value => { description = value; } );
    let productGroupName;
    this.translate.get('productGroupName') .subscribe(value => { productGroupName = value; } );
    let groupSummary;
    this.translate.get('groupSummary') .subscribe(value => { groupSummary = value; } );
    let isFound;
    this.translate.get('is_found') .subscribe(value => { isFound = value; } );
    let withoutGroup;
    this.translate.get('withoutGroup') .subscribe(value => { withoutGroup = value; } );

    // Sortowanie alfabetycznie po nazwie
    data.products.sort(function(a, b) {
      const A = a.name.toLowerCase();
      const B = b.name.toLowerCase();
      return (A < B) ? -1 : (A > B) ? 1 : 0;
    });
    // Sortowanie znalezione/nieznalezione
    data.products.sort(function(a, b) {
      const A = a.isFound.toString().toLowerCase();
      const B = b.isFound.toString().toLowerCase();
      return (A < B) ? -1 : (A > B) ? 1 : 0;
    });

    const rows = [];
    const rows2 = [];
    const rows3 = [];

    //Nagłowek 1:
    // data.products.forEach(item => {
    //   let temp;
    //   if (item.isFound) {
    //     if(item.productGroupName != null || item.productGroupName == ""){
    //       temp = [{text: item.name}, {text: item.tagEPC}, {text: item.productGroupName}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M15,7.5c0,4.142 -3.358,7.5 -7.5,7.5c-4.142,0 -7.5,-3.358 -7.5,-7.5c0,-4.142 3.358,-7.5 7.5,-7.5c4.142,0 7.5,3.358 7.5,7.5Z" style="fill:#4caf50;fill-rule:nonzero;"/><path d="M11.476,3.976l-5.101,5.099l-2.101,-2.1l-1.049,1.049l3.15,3.151l6.149,-6.15l-1.048,-1.049Z" style="fill:#ccff90;fill-rule:nonzero;"/></svg>', alignment: 'center'}];
    //     }
    //     else
    //     {
    //       temp = [{text: item.name}, {text: item.tagEPC}, {text: withoutGroup}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M15,7.5c0,4.142 -3.358,7.5 -7.5,7.5c-4.142,0 -7.5,-3.358 -7.5,-7.5c0,-4.142 3.358,-7.5 7.5,-7.5c4.142,0 7.5,3.358 7.5,7.5Z" style="fill:#4caf50;fill-rule:nonzero;"/><path d="M11.476,3.976l-5.101,5.099l-2.101,-2.1l-1.049,1.049l3.15,3.151l6.149,-6.15l-1.048,-1.049Z" style="fill:#ccff90;fill-rule:nonzero;"/></svg>', alignment: 'center'}];
    //     }        
    //   }
    //   else 
    //   {
    //     if(item.productGroupName != null || item.productGroupName == ""){
    //       temp = [{text: item.name}, {text: item.tagEPC}, {text: item.productGroupName}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="surface73761"><path d="M15,7.5c0,4.143 -3.357,7.5 -7.5,7.5c-4.143,0 -7.5,-3.357 -7.5,-7.5c0,-4.143 3.357,-7.5 7.5,-7.5c4.143,0 7.5,3.357 7.5,7.5Z" style="fill:#f44336;fill-rule:nonzero;"/><path d="M9.621,4.318l1.061,1.061l-5.303,5.303l-1.061,-1.061l5.303,-5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/><path d="M10.682,9.621l-1.061,1.061l-5.303,-5.303l1.061,-1.061l5.303,5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/></g></svg>', alignment: 'center'}];
    //     }
    //     else
    //     {
    //       temp = [{text: item.name}, {text: item.tagEPC}, {text: withoutGroup}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="surface73761"><path d="M15,7.5c0,4.143 -3.357,7.5 -7.5,7.5c-4.143,0 -7.5,-3.357 -7.5,-7.5c0,-4.143 3.357,-7.5 7.5,-7.5c4.143,0 7.5,3.357 7.5,7.5Z" style="fill:#f44336;fill-rule:nonzero;"/><path d="M9.621,4.318l1.061,1.061l-5.303,5.303l-1.061,-1.061l5.303,-5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/><path d="M10.682,9.621l-1.061,1.061l-5.303,-5.303l1.061,-1.061l5.303,5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/></g></svg>', alignment: 'center'}];
    //     }
    //   }
    //   rows.push(temp);
    // });

    // rows.unshift([{text: product, bold: true}, {text: epc, bold: true}, {text: productGroupName, bold: true}, {text: isFound, alignment: 'center', bold: true}]);

     //Nagłowek 2:
    let reportProcentageforGroups: number;
    let greenIndicatiorWidthforGroups: number;
    let svgStringtoGroups: string;
    let items;
  
    for(let i = 0; i < this.groupModel.length; i++){ 
      reportProcentageforGroups = Math.round(((this.groupModel[i].productsFromGroupFound / this.groupModel[i].productsFromGroup.length) * 100));
      greenIndicatiorWidthforGroups = 240 * reportProcentageforGroups / 100;
      svgStringtoGroups = '<svg width="240" height="15"><rect width="240" height="15" rx="10" ry="10" fill="#e6e6e6"/><rect width="'+greenIndicatiorWidthforGroups.toString()+'" rx="10" ry="10" height="15" fill="#32CD32"/></svg>'
      if(this.groupModel[i].groupName == null){
        items = [{text: withoutGroup}, {text: this.groupModel[i].productsFromGroupFound + '/' + this.groupModel[i].productsFromGroup.length.toString() + ' (' + reportProcentageforGroups.toString() + '%)'}, {svg: svgStringtoGroups}];
      }
      else{
        items = [{text: this.groupModel[i].groupName}, {text: this.groupModel[i].productsFromGroupFound + '/' + this.groupModel[i].productsFromGroup.length.toString() + ' (' + reportProcentageforGroups.toString() + '%)'}, {svg: svgStringtoGroups}];
      }
      
      rows2.push(items);   
    }
    
    rows2.unshift([{text: productGroupName + ":", bold: true}, {text: report_status + ":", bold: true}, {text: ''}]);

    //Nagłówek 3:
    let group2: string;
    let bool: boolean;
    let group3: string;

    for(var i=0; i<this.groupModel.length; i++){

       group2 = this.groupModel[i].groupName;
  
        for(var a =0; a < this.groupModel[i].productsFromGroup.length; a++){

          if(group3 == group2 || group3 == "" || group3 == null && group2 == null)
          {
            bool = true;
          }
          else
          {
            bool = false;
          }
          group3 = group2;
          
            let temp;
            if(bool == true){
              if (this.groupModel[i].productsFromGroup[a].isFound) {
                if(this.groupModel[i].productsFromGroup[a].productGroupName != null || this.groupModel[i].productsFromGroup[a].productGroupName == ""){
                  temp = [{text: group2}, {text: this.groupModel[i].productsFromGroup[a].name}, {text: this.groupModel[i].productsFromGroup[a].tagEPC}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M15,7.5c0,4.142 -3.358,7.5 -7.5,7.5c-4.142,0 -7.5,-3.358 -7.5,-7.5c0,-4.142 3.358,-7.5 7.5,-7.5c4.142,0 7.5,3.358 7.5,7.5Z" style="fill:#4caf50;fill-rule:nonzero;"/><path d="M11.476,3.976l-5.101,5.099l-2.101,-2.1l-1.049,1.049l3.15,3.151l6.149,-6.15l-1.048,-1.049Z" style="fill:#ccff90;fill-rule:nonzero;"/></svg>', alignment: 'center'}];
                }
                else
                {
                  temp = [{text: withoutGroup}, {text: this.groupModel[i].productsFromGroup[a].name}, {text: this.groupModel[i].productsFromGroup[a].tagEPC}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M15,7.5c0,4.142 -3.358,7.5 -7.5,7.5c-4.142,0 -7.5,-3.358 -7.5,-7.5c0,-4.142 3.358,-7.5 7.5,-7.5c4.142,0 7.5,3.358 7.5,7.5Z" style="fill:#4caf50;fill-rule:nonzero;"/><path d="M11.476,3.976l-5.101,5.099l-2.101,-2.1l-1.049,1.049l3.15,3.151l6.149,-6.15l-1.048,-1.049Z" style="fill:#ccff90;fill-rule:nonzero;"/></svg>', alignment: 'center'}];
                }        
              }
              else 
              {
                if(this.groupModel[i].productsFromGroup[a].productGroupName != null || this.groupModel[i].productsFromGroup[a].productGroupName == ""){
                  temp = [{text: group2}, {text: this.groupModel[i].productsFromGroup[a].name}, {text: this.groupModel[i].productsFromGroup[a].tagEPC}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="surface73761"><path d="M15,7.5c0,4.143 -3.357,7.5 -7.5,7.5c-4.143,0 -7.5,-3.357 -7.5,-7.5c0,-4.143 3.357,-7.5 7.5,-7.5c4.143,0 7.5,3.357 7.5,7.5Z" style="fill:#f44336;fill-rule:nonzero;"/><path d="M9.621,4.318l1.061,1.061l-5.303,5.303l-1.061,-1.061l5.303,-5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/><path d="M10.682,9.621l-1.061,1.061l-5.303,-5.303l1.061,-1.061l5.303,5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/></g></svg>', alignment: 'center'}];
                }
                else
                {
                  temp = [{text: withoutGroup}, {text: this.groupModel[i].productsFromGroup[a].name}, {text: this.groupModel[i].productsFromGroup[a].tagEPC}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="surface73761"><path d="M15,7.5c0,4.143 -3.357,7.5 -7.5,7.5c-4.143,0 -7.5,-3.357 -7.5,-7.5c0,-4.143 3.357,-7.5 7.5,-7.5c4.143,0 7.5,3.357 7.5,7.5Z" style="fill:#f44336;fill-rule:nonzero;"/><path d="M9.621,4.318l1.061,1.061l-5.303,5.303l-1.061,-1.061l5.303,-5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/><path d="M10.682,9.621l-1.061,1.061l-5.303,-5.303l1.061,-1.061l5.303,5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/></g></svg>', alignment: 'center'}];
                }
              }    
            }
            else if(bool == false){
              if (this.groupModel[i].productsFromGroup[a].isFound) {
                if(this.groupModel[i].productsFromGroup[a].productGroupName != null || this.groupModel[i].productsFromGroup[a].productGroupName == ""){
                  temp = [{text: group2, margin: [0,15,0,0], bold: true}, {text: this.groupModel[i].productsFromGroup[a].name, margin: [0,15,0,0]}, {text: this.groupModel[i].productsFromGroup[a].tagEPC, margin: [0,15,0,0]}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M15,7.5c0,4.142 -3.358,7.5 -7.5,7.5c-4.142,0 -7.5,-3.358 -7.5,-7.5c0,-4.142 3.358,-7.5 7.5,-7.5c4.142,0 7.5,3.358 7.5,7.5Z" style="fill:#4caf50;fill-rule:nonzero;"/><path d="M11.476,3.976l-5.101,5.099l-2.101,-2.1l-1.049,1.049l3.15,3.151l6.149,-6.15l-1.048,-1.049Z" style="fill:#ccff90;fill-rule:nonzero;"/></svg>', alignment: 'center', margin: [0,15,0,0]}];
                }
                else
                {
                  temp = [{text: withoutGroup, margin: [0,15,0,0], bold: true}, {text: this.groupModel[i].productsFromGroup[a].name, margin: [0,15,0,0]}, {text: this.groupModel[i].productsFromGroup[a].tagEPC, margin: [0,15,0,0]}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M15,7.5c0,4.142 -3.358,7.5 -7.5,7.5c-4.142,0 -7.5,-3.358 -7.5,-7.5c0,-4.142 3.358,-7.5 7.5,-7.5c4.142,0 7.5,3.358 7.5,7.5Z" style="fill:#4caf50;fill-rule:nonzero;"/><path d="M11.476,3.976l-5.101,5.099l-2.101,-2.1l-1.049,1.049l3.15,3.151l6.149,-6.15l-1.048,-1.049Z" style="fill:#ccff90;fill-rule:nonzero;"/></svg>', alignment: 'center', margin: [0,15,0,0]}];
                }        
              }
              else 
              {
                if(this.groupModel[i].productsFromGroup[a].productGroupName != null || this.groupModel[i].productsFromGroup[a].productGroupName == ""){
                  temp = [{text: group2, margin: [0,15,0,0], bold: true}, {text: this.groupModel[i].productsFromGroup[a].name, margin: [0,15,0,0]}, {text: this.groupModel[i].productsFromGroup[a].tagEPC, margin: [0,15,0,0]}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="surface73761"><path d="M15,7.5c0,4.143 -3.357,7.5 -7.5,7.5c-4.143,0 -7.5,-3.357 -7.5,-7.5c0,-4.143 3.357,-7.5 7.5,-7.5c4.143,0 7.5,3.357 7.5,7.5Z" style="fill:#f44336;fill-rule:nonzero;"/><path d="M9.621,4.318l1.061,1.061l-5.303,5.303l-1.061,-1.061l5.303,-5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/><path d="M10.682,9.621l-1.061,1.061l-5.303,-5.303l1.061,-1.061l5.303,5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/></g></svg>', alignment: 'center', margin: [0,15,0,0]}];
                }
                else
                {
                  temp = [{text: withoutGroup, margin: [0,15,0,0], bold: true}, {text: this.groupModel[i].productsFromGroup[a].name, margin: [0,15,0,0]}, {text: this.groupModel[i].productsFromGroup[a].tagEPC, margin: [0,15,0,0]}, {svg: '<svg width="15" height="15" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="surface73761"><path d="M15,7.5c0,4.143 -3.357,7.5 -7.5,7.5c-4.143,0 -7.5,-3.357 -7.5,-7.5c0,-4.143 3.357,-7.5 7.5,-7.5c4.143,0 7.5,3.357 7.5,7.5Z" style="fill:#f44336;fill-rule:nonzero;"/><path d="M9.621,4.318l1.061,1.061l-5.303,5.303l-1.061,-1.061l5.303,-5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/><path d="M10.682,9.621l-1.061,1.061l-5.303,-5.303l1.061,-1.061l5.303,5.303Z" style="fill:#fefefe;fill-rule:nonzero;"/></g></svg>', alignment: 'center', margin: [0,15,0,0]}];
                }
              }    
            }          
            rows3.push(temp);  
        }    
}
rows3.unshift([{text: productGroupName, bold: true}, {text: product, bold: true}, {text: epc, bold: true}, {text: isFound, alignment: 'center', bold: true}]);
// <----

    // Oblicz procent statusu raportu
    const reportProcentage = Math.round(((data.productsFound / data.products.length) * 100));
    const greenIndicatiorWidth = 510 * reportProcentage / 100;

    const svgString = '<svg width="510" height="20"><rect width="510" height="20" rx="10" ry="10" fill="#e6e6e6"/><rect width="'+greenIndicatiorWidth.toString()+'" rx="10" ry="10" height="20" fill="#32CD32"/></svg>'
    // Znak wodny
    const documentDefinition = {
      info: {
        title: 'InventoryReport_' + data.location + '_' + data.dateTime + '.pdf',
      },
      watermark: { text: 'ISS RFID', color: 'blue', angle: 0, opacity: 0.1, bold: true, italics: false },
      footer: function(currentPage, pageCount) {
        return [
          {text: (currentPage.toString() + '/' + pageCount), alignment: 'center'},
        ];
      },
      header: function(currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element

        return [
          { text: title, alignment: 'center', fontSize: 16, bold: true, color: 'blue', opacity: 0.4, margin: [10, 10] },
          { canvas: [ { type: 'rect', x: 170, y: 32, w: pageSize.width - 170, h: 40 } ] },
        ];
      },
      content: [
        {
          columns: [
            {
              alignment: 'center',
              width: 170,
              image: await this.getBase64ImageFromURL('assets/img/fa_user.png'),
              fit: [20, 20],
            },

            {
              alignment: 'center',
              width: 170,
              image: await this.getBase64ImageFromURL('assets/img/fa_calendar.png'),
              fit: [20, 20],
            },

            {
              alignment: 'center',
              width: 170,
              image: await this.getBase64ImageFromURL('assets/img/fa_map_marker.png'),
              fit: [20, 20],
            },
          ]
        },
        {
          columns: [
            {
              alignment: 'center',
              width: 170,
              text: data.uploader,
              margin: [5, 10]
            },

            {
              alignment: 'center',
              width: 170,
              text: reportTime,
              margin: [5, 10]
            },

            {
              alignment: 'center',
              width: 170,
              text: this.locationService.getLocationNameByName(data.location),
              margin: [5, 10]
            }
          ]

        },
        {
          columns: [
            {
              alignment: 'left',
              width: 510,
              // tslint:disable-next-line: max-line-length
              text: report_status + ' ' + data.productsFound + '/' + data.products.length.toString() + ' (' + reportProcentage.toString() + '%)',
              bold: true,
              margin: [5, 10, 0, 5]
            },
          ]

        },
        {
          columns: [
            {
            alignment: 'center',
            width: 510,
            height: 30,
            svg: svgString,
            margin: [0, 0, 0, 10],

          },
          ]

        },
        // {
        //   layout: 'lightHorizontalLines', // optional
        //   table: {
        //     headerRows: 1,
        //     widths: [100, 150, 150, '*'],
        //     body: rows
        //   },
        //   margin: [0, 0, 0, 50]
        // }
        //,
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            headerRows: 2,
            widths: [100, 150, 240],
            body: rows2
          },
          margin: [0, 0, 0, 50]
        }
        ,      
         {
          layout: 'lightHorizontalLines', // optional
          table: {
            headerRows: 3,
            widths: [100, 150, 150, '*'],
            body: rows3
          },
          margin: [0, 0, 0, 50]
        }      
      ]
     };
    pdfMake.createPdf(documentDefinition).download('InventoryReport_' +
                                                   this.locationService.getLocationNameByName(data.location) +
                                                   '_' + data.dateTime + '.pdf');
    //pdfMake.createPdf(documentDefinition).open();
  }


  onPrintCSVReport(data: InventoryReport) {
    let name; this.translate.get('name') .subscribe(value => { name = value; } );
    let tag_name; this.translate.get('tag_name') .subscribe(value => { tag_name = value; } );
    let tag_epc; this.translate.get('tag_epc') .subscribe(value => { tag_epc = value; } );
    let description; this.translate.get('description') .subscribe(value => { description = value; } );
    let productGroupName; this.translate.get('productGroupName') .subscribe(value => { productGroupName = value; } );
    let isFound; this.translate.get('is_found') .subscribe(value => { isFound = value; } );
    let yes; this.translate.get('yes') .subscribe(value => { yes = value; } );
    let no; this.translate.get('no') .subscribe(value => { no = value; } );
    const header: string[] = [name, description, tag_epc, tag_name, productGroupName, isFound];
    const printInventoryList: CsvExportReport[] = [];
    data.products.forEach(p => {
      printInventoryList.push({'name': p.name ? p.name : '',
                             'description': p.description ? p.description : '',
                             'tagEPC': p.tagEPC ? p.tagEPC + ';' : '',
                             'tagName': (p.tagName !== 'null') && p.tagName ? p.tagName : '',
                             'productGroupName': p.productGroupName ? p.productGroupName : '',
                             'isFound': p.isFound ? yes : no
                            });
    });
    // Sortowanie alfabetycznie po nazwie
    printInventoryList.sort(function(a, b) {
      const A = a.name.toLowerCase();
      const B = b.name.toLowerCase();
      return (A < B) ? -1 : (A > B) ? 1 : 0;
    });
    // Sortowanie znalezione/nieznalezione
    printInventoryList.sort(function(a, b) {
      const A = a.isFound.toString().toLowerCase();
      const B = b.isFound.toString().toLowerCase();
      return (A < B) ? -1 : (A > B) ? 1 : 0;
    });



    const CSVoptions = {
      filename: 'InventoryReport_' + this.locationService.getLocationNameByName(data.location) + '_' + data.dateTime,
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Product List',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: header
    };
    const csvExporter = new ExportToCsv(CSVoptions);
    csvExporter.generateCsv(printInventoryList);
  }

  onPermanentDelete(report: InventoryReport) {
    let dialogData;
    this.translate.get('confirm_delete').subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.location.reload();
        this.blockUI.start();
        let toastrData;
        this.service.deletePermanentReport(report.id).subscribe(
          (res: any) => {
            this.service.LoadUserProducts();
            this.translate.get('product_delete_success').subscribe(value => { toastrData = value; } );
            //this.reportService.LoadUserReports();
            this.toastr.success(toastrData);
            this.blockUI.stop();
          },
          (err) => {
            console.log('Error:' + err);
            this.blockUI.stop();
          });
        }
      });
    }

    onDateSelection(date: NgbDate) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
        this.storeService.reportFilterFromDate = date;
      } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
        this.toDate = date;
        this.storeService.reportFilterToDate = date;
      } else {
        this.toDate = null;
        this.fromDate = date;
        this.storeService.reportFilterFromDate = date;
        this.storeService.reportFilterToDate = null;
      }
      //this.reportService.FilterReportsByDate(this.fromDate, this.toDate);
      this.reportService.TakeReportsByDate(this.fromDate, this.toDate);
    }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }
}

