import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import * as _ from 'node_modules/lodash';
import { IProduct, ILocation } from 'src/model/apitypes';
import { ProductService } from '../../services/product/product.service';
@Pipe({
  name: 'searchLocationPipe',
  pure: false
})
export class SearchLocationPipe implements PipeTransform {

  transform(list: ILocation[], searchText: string, pipe: PipeTransform): ILocation[] {
    if (!list) { return []; }
    if (!searchText) { return list; }

    list.forEach(location => {
      if (location.name === null) { location.name = ' '; }
      if (location.address === null) { location.address = ' '; }
      if (location.city === null) { location.city = ' '; }
      if (location.country === null) { location.country = ' '; }
    });
    searchText = searchText.toLowerCase();
    return list.filter( item => {
          return item.name.toLowerCase().includes(searchText)
          || item.address.toLowerCase().includes(searchText)
          || item.city.toLowerCase().includes(searchText)
          || item.country.toLowerCase().includes(searchText);
        });
      }
}
