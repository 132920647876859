import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root'
  })

export class DeviceService {

    header = new HttpHeaders().set('Content-type', 'application/json');

    constructor(private http: HttpClient) {
    }

    getKeyboardReaderVersion() {
        return this.http.get("../" + 'update/updateInfo.json');
    }

    getBluetoothReaderVersion() {
        return this.http.get("../" + 'update/updateInfoBluetooth.json');
    }

}