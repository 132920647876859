import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import * as _ from 'node_modules/lodash';
import {  InventoryReport } from 'src/model/apitypes';
@Pipe({
  name: 'SearchReportPipe',
  pure: false
})
export class SearchReportPipe implements PipeTransform {

  transform(list: InventoryReport[], searchText: string, pipe: PipeTransform): InventoryReport[] {
    if (!list) { return []; }
    if (!searchText) { return list; }

    list.forEach(report => {
      if (report.location === null) { report.location = ' '; }
      if (report.uploader === null) { report.uploader = ' '; }
    });
    searchText = searchText.toLowerCase();
    return list.filter( item => {
          return item.location.toLowerCase().includes(searchText)
          || item.uploader.toLowerCase().includes(searchText);
        });
      }
}
