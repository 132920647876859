import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdministratorUserModel } from 'src/model/adminApiTypes';

@Component({
  selector: 'app-license-user-dialog',
  templateUrl: './license-user-dialog.component.html',
  styleUrls: ['./license-user-dialog.component.scss']
})
export class LicenseUserDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LicenseUserDialogComponent>,
    public translate: TranslateService,
    public service: AdministratorService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public userModel: AdministratorUserModel) { }

  ngOnInit() {
  }

  form: FormGroup = new FormGroup({
    expirationDate: new FormControl('', Validators.required),
  });

  onSetLicense(userModel: AdministratorUserModel){
    let expirationDateWitoutTimezoneOffset = null;
    if (this.form.value.expirationDate != null && this.form.value.expirationDate != "") {
      expirationDateWitoutTimezoneOffset = new Date(this.form.value.expirationDate);
      expirationDateWitoutTimezoneOffset.setMinutes
      (expirationDateWitoutTimezoneOffset.getMinutes() - expirationDateWitoutTimezoneOffset.getTimezoneOffset());
    }
    else {
      this.onErrorDateToast('invalid_date');
      return;
    }
    userModel.licenseExpirationDate = expirationDateWitoutTimezoneOffset;
    this.service.setLicenseUserAccount(userModel).subscribe(
      (res:any) => {
        this.dialogRef.close({event:'Success'});
      },
      err => {
        userModel.licenseExpirationDate = null;
        this.onErrorDateToast('server_error')
      },
    );
  }

  onErrorDateToast(msg: string){
    let toastrData;
    this.translate.get(msg) .subscribe(value => { toastrData = value; } );
    this.toastr.error(toastrData);
  }

  onClose() {
    this.dialogRef.close();
  }

}
