import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ILocation } from 'src/model/apitypes';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from 'src/app/services/store/store.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class LocationComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  constructor(public service: UserService,
    public dialogRef: MatDialogRef<LocationComponent>,
    private toastr: ToastrService,
    public translate: TranslateService,
    public storeService: StoreService) {
  }


  EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  TEL_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  form: FormGroup = new FormGroup ({
    $key: new FormControl(0),
    name: new FormControl('', Validators.required),
    address: new FormControl(''),
    city: new FormControl(''),
    country: new FormControl(''),
    email: new FormControl('', Validators.pattern(this.EMAIL_REGEX)),
    phone: new FormControl('', Validators.pattern(this.TEL_REGEX)),
    postalCode: new FormControl(''),
    state: new FormControl('')
  });

  isShown: boolean = false ;

  ngOnInit() {
    if (this.service.getLocation() == null) {
      if (this.storeService.addLocationFormStored) {
        this.readStoredFormData();
      } else {
        this.initializeCreateFormGroup();
      }
    } else {
      this.initializeUpdateFormGroup(this.service.getLocation());
    }
    if (window.screen.width <= 884) {
      this.isShown = true;
    }
  }

  initializeCreateFormGroup() {
    this.form.setValue({
      $key: 0,
      name: null,
      address: null,
      city: null,
      country: null,
      email: null,
      phone: null,
      postalCode: null,
      state: null
    });
  }

  initializeClearFormGroup(location: ILocation) {
    this.form.setValue({
      $key: location.id,
      name: null,
      address: null,
      city: null,
      country: null,
      email: null,
      phone: null,
      postalCode: null,
      state: null
    });
  }

  initializeUpdateFormGroup(location: ILocation) {
    this.form.setValue({
      $key: location.id,
      name: location.name,
      address: location.address,
      city: location.city,
      country: location.country,
      email: location.email,
      phone: location.phone,
      postalCode: location.postalCode,
      state: location.state
    });
  }

  body: any;
  getFormBody()  {
    return this.body = {
      id: this.form.value.$key,
      name: this.form.value.name,
      address: this.form.value.address,
      city: this.form.value.city,
      country: this.form.value.country,
      email: this.form.value.email ? this.form.value.email : null,
      phone: this.form.value.phone ? this.form.value.phone : null,
      postalCode: this.form.value.postalCode,
      state: this.form.value.state
    };
  }

  onSubmit() {
    this.blockUI.start();
    if (this.service.getLocation() == null) {
      let dialogData;
      this.translate.get('location_creation_failed').subscribe(value => { dialogData = value; } );
      this.service.createLocation(this.getFormBody()).subscribe(
        (res: any) => {
          this.translate.get('location_creation_success').subscribe(value => { dialogData = value; } );
          this.toastr.success(dialogData, res.Message),
          this.onClose(true);
          this.storeService.addLocationFormStored = false;
          },
          (err) => {
            if (err.error.message === 'location_already_exist') {
              this.translate.get('location_already_exist').subscribe(value => { dialogData = value; } );
              this.toastr.warning(dialogData);
              this.blockUI.stop();
            } else {
              this.toastr.error(dialogData);
              this.onClose(true);
              this.blockUI.stop();
            }
          }
      );
    } else {
      let dialogData;
      this.service.updateLocation(this.getFormBody()).subscribe(
        (res: any) => {
            this.translate.get('location_update_success').subscribe(value => { dialogData = value; } );
            this.toastr.success(dialogData, this.form.value.name),
            this.onClose(true);
          },
          (err) => {
            this.translate.get('location_update_failed').subscribe(value => { dialogData = value; } );
            this.toastr.error(dialogData);
            this.onClose(true);
          }
      );
    }
  }


  onClear() {
    if (this.service.getLocation()) {
      this.initializeClearFormGroup(this.service.getLocation());
    } else {
      this.initializeCreateFormGroup();
    }
  }

  onClose(isUpload) {
    this.dialogRef.close(isUpload);
    this.blockUI.stop();
    if (this.service.getLocation() == null) {
      this.storeService.SaveAddLocationData(this.form);
    }
  }
  readStoredFormData() {
    this.form.setValue(this.storeService.ReadAddLocationData().value);
  }
}
