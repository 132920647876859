import { Injectable, ViewChild, EventEmitter } from '@angular/core';
import { Observable, Observer, of, BehaviorSubject } from 'rxjs';
import { UserService } from '../user/user.service';
import { IProduct, ApiUpdateProductsLocation, ApiDeleteProduct } from 'src/model/apitypes';
import { catchError } from 'rxjs/operators';
import * as _ from 'node_modules/lodash';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '../../app-state.service';
import { NotificationService } from '../notification/notification.service';
import { CategoryService } from '../category/category.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  products$: Observable<IProduct[]> ;

  private _products = new BehaviorSubject<IProduct[]>([]);
  private _deletedProducts = new BehaviorSubject<IProduct[]>([]);

  public productStore: { products: IProduct[] } = { products: [] };
  private deletedProductStore: { deletedProducts: IProduct[] }  = { deletedProducts: [] };
  readonly products = this._products.asObservable();
  readonly deletedProducts = this._deletedProducts.asObservable();

  public imageUploadTimeStamp = (new Date()).getTime();

  public ProductsLoadedValue = false;
  public ProductsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();



  @BlockUI() blockUI: NgBlockUI;

  constructor(private service: UserService,
              public translate: TranslateService,
              public appState: AppStateService,
              private categoryService: CategoryService) {  }


public getProductsLocation(body) {
  this.products$ = this.service.getUserProductsFromLocation(body)
  .pipe(
    catchError(this.handleError<IProduct[]>('getProductsLocation', []))
  );
}

public getProducts() {
  this.products$ = this.service.getUserProducts()
  .pipe(
    catchError(this.handleError<IProduct[]>('getProducts', []))
  );
}

loadAll() {
  this.blockUI.start();
  this.service.getUserProducts()
    .subscribe((response: IProduct[]) => {
        this.productStore.products = response;
        this._products.next(Object.assign({}, this.productStore).products);
        this.createCategoriesListForLocation(null);
        this.ProductsLoadedValue = true;
        this.ProductsLoaded.emit(true);
        this.blockUI.stop();
      }
  );
  this.blockUI.start();
  this.service.getUserProductsFromTrash()
    .subscribe((response: IProduct[]) => {
      this.deletedProductStore.deletedProducts = response;
      this._deletedProducts.next(Object.assign({}, this.deletedProductStore).deletedProducts);
      this.blockUI.stop();
    }
  );
}

async createCategoriesListForLocation(location: string) {
  this.categoryService.filteredCategories = [];
  this.categoryService.categoriesNames = [];
  if (location) {
    this.productStore.products.forEach(p => {
      if ((!this.categoryService.categoriesNames.includes(p.productCategory.name)) && p.location.name === location) {
        if (p.productCategory) {
          this.categoryService.filteredCategories.push(p.productCategory);
          this.categoryService.categoriesNames.push(p.productCategory.name);
        }
      }
    });
  } else {
    await this.categoryService.LoadUserCategories();
    this.categoryService.filteredCategories = this.categoryService.filteredCategories;
  }
}

public restoreProductFromTrash(product: IProduct) {
  this.updateProduct(product);
  this.deletedProductStore.deletedProducts.forEach((t, i) => {
    if (t.id === product.id) {
      this.deletedProductStore.deletedProducts.splice(i, 1);
      this._products.next(Object.assign({}, this.deletedProductStore).deletedProducts);
      return;
    }
  });
}
public restoreSelectedProductsFromTrash(products: IProduct[]) {
    products.forEach(p => {
      this.restoreProductFromTrash(p);
  });
}

public avaibleProducts() {
  return _.filter(this.productStore.products, function(o) { return !o.isDeleted; });
}

public productsInTrash(){
  return _.filter(this.deletedProductStore.deletedProducts, function(o) { return o.isDeleted });
}

public cleanSelectedProducts() {
  this.productStore.products.forEach(function (product) {
    product.selected = false;
  });
}
public cleanDeletedSelectedProducts() {
  this.deletedProductStore.deletedProducts.forEach(function (product) {
    product.selected = false;
  });
}
public checkAllDeletedSelectedProducts() {
  this.deletedProductStore.deletedProducts.forEach(function (product) {
    product.selected = true;
  });
}
public checkAllProducts() {
  //console.log( this.appState.selectedLocation.name  + this.appState.selectedCategory);
  if (this.appState.selectedLocation === null && this.appState.selectedCategory === null) {
    this.productStore.products.forEach(p => {
        p.selected = true;
    });
  }
  if (this.appState.selectedLocation !== null && this.appState.selectedCategory === null) {
    this.productStore.products.forEach(p => {
      if (p.location.id === this.appState.selectedLocation.id) {
        p.selected = true;
      } else {
        p.selected = false;
      }
  });
  }
  if (this.appState.selectedLocation === null && this.appState.selectedCategory !== null) {
    this.productStore.products.forEach(p => {
      if (p.productCategory.name === this.appState.selectedCategory.name) {
        p.selected = true;
      } else {
        p.selected = false;
      }
  });
  }
  if (this.appState.selectedLocation !== null && this.appState.selectedCategory !== null) {
    this.productStore.products.forEach(p => {
      if (p.location.id === this.appState.selectedLocation.id && p.productCategory.name === this.appState.selectedCategory.name) {
        p.selected = true;
      } else {
        p.selected = false;
      }
  });
}

}

public selectedProducts(): IProduct[]{
  return _.filter(this.productStore.products, 'selected');
}
public selectedDeletedProducts(): IProduct[] {
  return _.filter(this.deletedProductStore.deletedProducts, 'selected');
}

public selectedProductsCount(): number{
  return _.filter(this.productStore.products, 'selected').length;
}
public selectedDeletedProductsCount(): number{
  return _.filter(this.deletedProductStore.deletedProducts, 'selected').length;
}

public moveSelectedItemsToLocation(selectedLoactionToMoveItems: number): Promise<string> {
let updateProductsLocations: ApiUpdateProductsLocation = <ApiUpdateProductsLocation> { productsIDs: [], locationID: 0};
const selectedProducts = this.selectedProducts();
selectedProducts.forEach(p => {
  updateProductsLocations.productsIDs.push(p.id);
});
updateProductsLocations.locationID = selectedLoactionToMoveItems;
  if (selectedProducts.length > 0 && selectedLoactionToMoveItems !== 0) {
    return this.service.updateProductsLocations(updateProductsLocations).toPromise()
      .then((res) => {
        this.loadAll();
        return true;
      },
      (err) => {
        console.log(err);
        return null;
      });
  }
}

public deleteSelectedProducts(): Promise<string> {
  const selectedProducts = this.selectedProducts();

  if (selectedProducts.length > 0) {

    let apiProductsToDelete = selectedProducts.map(x=>{
      return <ApiDeleteProduct>
      {
          productID : x.id,
          locationID : x.location.id
      };
    });

    return this.service.deleteProducts(apiProductsToDelete).toPromise()
    .then((res: IProduct[]) => {
        for (const product of res) {
          this.deleteProduct(product.id);
        }
        return selectedProducts.length + ' product(s) moved to trash.';
      },
      (err) => {
        console.log(err);
        return null;
      });
  }
}


public SearchProductsForSelectedLocationAndCategory(locationId: number, categoryName: String) {
  if (locationId === 0 && categoryName === null) {
    this._products.next(Object.assign({}, this.productStore).products);
  }
  if (locationId !== 0 && categoryName === null) {
    this._products.next(Object.assign({}, this.productStore).
    products.filter(product => product.location.id === locationId));
  }
  if (locationId === 0 && categoryName !== null) {
    this._products.next(Object.assign({}, this.productStore).
    products.filter(product => product.category === categoryName));
  }
  if (locationId !== 0 && categoryName !== null) {
    this._products.next(Object.assign({}, this.productStore).
    products.filter(product => (product.category === categoryName) && (product.location.id === locationId) ));
  }
}


public updateProduct(product: IProduct) {
  this.productStore.products.forEach((t, i) => {
    if (t.id === product.id) {
      this.productStore.products[i] = product;
      this._products.next(Object.assign({}, this.productStore).products);
      return;
    }
  });
}

public deleteProduct(productToDeleteID: number){
  this.productStore.products.forEach((t, i) => {
    if (t.id === productToDeleteID) {
      this.productStore.products.splice(i, 1);
      this._products.next(Object.assign({}, this.productStore).products);
      return;
    }
  });
}

public listExpiredProducts(): IProduct[] {
  const currentDate = new Date();
  return _.filter(this.productStore.products, function(o) { return ((new Date(o.expirationDate) < currentDate) && (o.expirationDate != null)); });
}

public listProductsCloseToExpirationDate(daysToExpirationDate: any): IProduct[] {
  const currentDate = new Date();
  const currentDateWithOffset = new Date(currentDate);
  currentDateWithOffset.setDate(currentDateWithOffset.getDate() + daysToExpirationDate);
  return _.filter(this.productStore.products, function(o) {
    return (
      (new Date(o.expirationDate) < currentDateWithOffset) &&
      (new Date(o.expirationDate) > currentDate) &&
      (o.expirationDate != null)
    );
  });
}


private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}
}

