import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'node_modules/lodash';
import { IProduct } from 'src/model/apitypes';

@Pipe({
  name: 'sortProduct',
  pure: false
})
export class SortProductPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let l = <IProduct[]>value;
    // no filter args - skip
    if (!args) {
      return l;
    }
    // empty - skip
    if (!l || l.length === 0 || l.length === 1) {
      return l;
    }

    // sort ASC or DESC
    let sort_str = <string>args;
    let parts = _.split(sort_str, ' ');
    let dir = parts.length === 2 ? parts[1].toString().toLowerCase() : 'asc';

    let sorted = _.orderBy(l,
        [ product => {
            if (product[parts[0]]) {
              if (product[parts[0]].name) {
                return product[parts[0]].name.toLowerCase();
              }
              return product[parts[0].toString()].toString().toLowerCase();
            } else {
              return '';
            }
          }
        ],
       <any>[dir]);
    
    console.log(parts);

  
    const collator = new Intl.Collator('pl');
    let sorted2 = sorted.sort(collator.compare);

    return sorted2;
  }

}
