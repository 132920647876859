import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {
  constructor(private http: HttpClient) { }

    public imageUploadTimeStamp = (new Date()).getTime();

    getUsers() {
        return this.http.get(AppConfig.settings.API_Address + '/Administrator/GetUsers');
    }

    getProducts() {
      return this.http.get(AppConfig.settings.API_Address + '/Administrator/GetProducts');
  }

    getAllUsersNotifications() {
      return this.http.get(AppConfig.settings.API_Address + '/Administrator/GetAllUsersNotifications');
  }

    activateUserAccount(body){
        return this.http.post(AppConfig.settings.API_Address + '/Administrator/ActivateUserAccount', body)
    }

    setLicenseUserAccount(body){
        return this.http.post(AppConfig.settings.API_Address + '/Administrator/SetLicenseUserAccount', body)
    }

    // generateDefaultLocations(){
    //     return this.http.post(AppConfig.settings.API_Address + '/Administrator/GenerateUsersDefaultLocations', null)
    // }

    generateDefaultCategories(){
        return this.http.post(AppConfig.settings.API_Address + '/Administrator/GenerateUsersDefaultCategories', null)
    }

    setDefaultCategories(){
        return this.http.post(AppConfig.settings.API_Address + '/Administrator/SetDefaultCategories', null)
    }

    getLocations(){
        return this.http.get(AppConfig.settings.API_Address + '/Administrator/GetLocations');
    }

    deleteLocation(body){
        return this.http.post(AppConfig.settings.API_Address + '/Administrator/DeleteUserLocation', body)
    }

    setPasswordUserAccount(body){
        return this.http.post(AppConfig.settings.API_Address + '/Administrator/ChangePassword', body)
    }
}
