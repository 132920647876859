import { ToastrService } from 'ngx-toastr';
import { RecoverPwdService } from '../../services/user/recoverpwd.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-login',
  templateUrl: './recoverpwd.component.html',
  styleUrls: ['./recoverpwd.component.scss']
})
export class RecoverPwdComponent implements OnInit {
  constructor(public service: RecoverPwdService, private toastr: ToastrService,
    private router: Router,
    public translate: TranslateService) { }

  ngOnInit() {
    this.service.formRecoverModel.reset();
  }

  onSubmit() {
    this.service.recover().subscribe(
      (res: any) => {
        if (res.message === 'Success') {
            this.service.formRecoverModel.reset();
            let keySuccess;
            let valueSuccess;
            this.translate.get('recoverInfoSend').subscribe(value => { keySuccess = value; } );
            this.translate.get('recoverInfoSendCorrect').subscribe(value => { valueSuccess = value; } );
            this.toastr.success(keySuccess, valueSuccess);
            this.router.navigate(['/ConfirmEmail']);

        }
        else
        {
          let keyError;
            let valueError;
            this.translate.get('cannotSendMessage').subscribe(value => { keyError = value; } );
            this.translate.get('contactToAdmin').subscribe(value => {valueError = value; } );
          this.toastr.error(keyError, valueError);
        }
      },
      err => {
        let keyError;
            let valueError;
            this.translate.get('cannotSendMessage').subscribe(value => { keyError = value; } );
            this.translate.get('contactToAdmin').subscribe(value => { valueError = value; } );
        this.toastr.error(keyError, valueError);
        console.log(err);
      }
    );
  }


}
