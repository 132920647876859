import { AppStateService } from '../../app-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../product/product.service';
import { IProduct, InventoryReport } from 'src/model/apitypes';
import { Injectable, OnInit } from '@angular/core';
import { ReportService } from '../report/report.service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';
import { ProductCreate } from 'src/model/Notifications/ProductCreate';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProductUpdate } from 'src/model/Notifications/ProductUpdate';
import { ProductExpirationDate } from 'src/model/Notifications/ProductExpirationDate';
import { ProductTransfer } from 'src/model/Notifications/ProductTransfer';
import { ProductDelete } from 'src/model/Notifications/ProductDelete';
import { ProductNotification } from 'src/model/Notifications/ProductNotification';
import { IProductNotification } from 'src/model/Notifications/IProductNotification';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Injectable()
export class NotificationService implements OnInit{

  expiredProducts: IProduct[] = [];
  expiredProductsExist: Boolean = false;
  expiredProductsCount: any = 0;

  productsCloseToExpiration: IProduct[] = [];
  productsCloseToExpirationExist: Boolean = false;
  productsCloseToExpirationCount: any = 0;

  newestReports: InventoryReport[];
  newestReportsExist: Boolean = false;
  newestReportsCount: any = 0;

  notifications$: Observable<IProductNotification[]> ;
  private _notifications = new BehaviorSubject<IProductNotification[]>(([]));
  readonly notofications = this._notifications.asObservable();
  public productNotification: { notofications: IProductNotification[] } = { notofications: [] };

  @BlockUI() blockUI: NgBlockUI;

  constructor(public productService: ProductService,
              public translate: TranslateService,
              public appState: AppStateService,
              private reportService: ReportService,
              private http: HttpClient) {}

  ngOnInit() {
  }

  getNotificationProductCreate() {
    return this.http.get(AppConfig.settings.API_Address + '/Notification/GetNotificationProductCreate');
  }

  getNotificationProductUpdate() {
    return this.http.get(AppConfig.settings.API_Address + '/Notification/GetNotificationProductUpdated');
  }

  getNotificationProductDelete(){
    return this.http.get(AppConfig.settings.API_Address + '/Notification/GetNotificationProductDeleted');
  }

  getNotificationProductTransfer(){
    return this.http.get(AppConfig.settings.API_Address + '/Notification/GetNotificationProductTransfer');
  }

  getNotificationProductExpirationDate(){
    return this.http.get(AppConfig.settings.API_Address + '/Notification/GetNotificationProductExpirationDate');
  }

  getNotifications() {
    return this.http.get(AppConfig.settings.API_Address + '/Notification/GetNotifications');
  }

  public loadAll() {
    this.blockUI.start();
    this.getNotifications().subscribe(
      (res: IProductNotification[]) => {
        this.productNotification.notofications = res;
        this._notifications.next(Object.assign({}, this.productNotification).notofications);
        this.RefreshNotificationsList();
        this.blockUI.stop();
      },
      err => {
        console.log(err);
        this.blockUI.stop();
      },
    );
  }

  private RefreshNotificationsList( ) {
    this.notifications$ = this.notofications;
  }

  public checkAllNotifications() {
    this.loadExpiredProducts();
    this.loadProductsCloseToExpirationDate();
    this.loadNewestReports();
    this.checkIfNotificationExist();
    this.checkNotificationsCount();
  }

  private loadNewestReports() {
    const numberOfDaysPast = 5;
    this.newestReports = this.reportService.ListReportFromLastNDays(numberOfDaysPast);
  }

  private loadExpiredProducts() {
    this.expiredProducts = this.productService.listExpiredProducts();
  }

  private loadProductsCloseToExpirationDate() {
    const daysToExpiration = 7;
    this.productsCloseToExpiration = this.productService.listProductsCloseToExpirationDate(daysToExpiration);
  }

  private checkIfNotificationExist() {
    if (this.expiredProducts.length !== 0) {
      this.expiredProductsExist = true;
    } else {
      this.expiredProductsExist = false;
    }
    if (this.productsCloseToExpiration.length !== 0) {
      this.productsCloseToExpirationExist = true;
    } else {
      this.productsCloseToExpirationExist = false;
    }
    if (this.newestReports.length !== 0) {
      this.newestReportsExist = true;
    } else {
      this.newestReportsExist = false;
    }
  }

  private checkNotificationsCount() {
    this.expiredProductsCount = this.expiredProducts.length;
    this.productsCloseToExpirationCount = this.productsCloseToExpiration.length;
    this.newestReportsCount = this.newestReports.length;
    const notificationCount = this.expiredProductsCount + this.productsCloseToExpirationCount + this.newestReportsCount;
    this.appState.updateNotifications(notificationCount);
  }


  public eraseExpiredProductNotification(i: any) {
    this.expiredProducts.splice(i, 1);
    this.checkNotificationsCount();
    this.checkIfNotificationExist();
  }

  public eraseCloseExpirationProductNotification(i: any) {
    this.productsCloseToExpiration.splice(i, 1);
    this.checkNotificationsCount();
    this.checkIfNotificationExist();
  }
  public eraseNewestReportsNotification(i: any) {
    this.newestReports.splice(i, 1);
    this.checkNotificationsCount();
    this.checkIfNotificationExist();
  }
}


