import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/app-state.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { UserDetails } from 'src/model/adminapitypes';

@Component({
  selector: 'app-home-admin',
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.scss']
})
export class HomeAdminComponent implements OnInit {

  userDetails;
  isShown: boolean = false ;

  constructor(
    private router: Router,
    public appState: AppStateService,
    public translate: TranslateService,
    private userService: UserService) { }

  ngOnInit() {
    if (window.screen.width <= 884) {
      this.isShown = true;
    }
    this.userService.getUserDetails().subscribe(
      (res: UserDetails) => {
        this.appState.userName = res.userName;
      },
      err => {
        console.log('appComponent GetuserDetails' + err);
        this.router.navigate(['/user/login']);
      },
    );
  }


  onLogout() {
    localStorage.removeItem('token');
    this.router.navigate(['/user/login']);
  }

}
