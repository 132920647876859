import { OnInit, EventEmitter, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserService } from '../user/user.service';
import * as _ from 'node_modules/lodash';
import { ILocation, DashboardLocation } from 'src/model/apitypes';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from 'src/app/app-state.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Injectable()
export class LocationService  {

  public LocationsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  locations$: Observable<ILocation[]> ;
  private _locations = new BehaviorSubject<ILocation[]>([]);
  readonly locations = this._locations.asObservable();
  public locationsStore: { locations: ILocation[] } = { locations: [] };
  public dashboardLocation: DashboardLocation[];
  @BlockUI() blockUI: NgBlockUI;
  constructor(public userService: UserService,
              public translate: TranslateService,
              public appStateService: AppStateService) {}



  public LoadUserLocations() {
    this.blockUI.start();
    this.userService.getUserLocations().subscribe(
      (res: ILocation[]) => {
        this.locationsStore.locations = res;

        //Sortowanie alfabetyczne
        this.locationsStore.locations.sort(function(a, b) {
          const A = a.name[0].toLowerCase();
          const B = b.name[0].toLowerCase();
          return (A < B) ? -1 : (A > B) ? 1 : 0;
        });

        this._locations.next(Object.assign({}, this.locationsStore).locations);
        this.RefreshLocationsList();
        this.LocationsLoaded.emit(true);
        this.blockUI.stop();
      },
      err => {
        console.log(err);
        this.blockUI.stop();
      },
    );

  }
  public getName(location: ILocation): string {
    if (location.unknownCollection) {
      let locationName;
      this.translate.get('default_location').subscribe(value => { locationName = value; } );
      return locationName;
    } else {
      return location.name;
    }
  }
  public getLocationNameByName(location: string): string {
    const userUnknownLocationName = this.appStateService.userName + '_unkown';

    if (location === userUnknownLocationName) {
      let locationName;
      this.translate.get('default_location').subscribe(value => { locationName = value; } );
      return locationName;
    } else {
      return location;
    }
  }
  private RefreshLocationsList() {
    this.locations$ = this.locations;
  }
  public getLocationsGeneralInfo() {
    this.blockUI.start();
    this.userService.getUserLocationsGeneralInfo().subscribe(
      (res: DashboardLocation[]) => {
        this.dashboardLocation = res;
        this.blockUI.stop();
      },
      err => {
        console.log(err);
        this.blockUI.stop();
      },
    );
  }
}


