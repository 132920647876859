import { Time } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

export interface IProduct {
    category: string;
    code: string;
    count: number;
    description: string;
    id: number;
    location: ILocation;
    isDeleted: boolean;
    markup: number;
    name: string;
    price: number;
    epcs: string[];
    tagName: string;
    tagPassword: string;
    tagTID?: string;
    tagTamper: boolean;
    tagUserData?: string;
    lastEditDate: Time;
    expirationDate: Date;
    image: FileResource;
    categoryId: number;
    // view only attributes
    selected: boolean;
    productCategory: ProductCategory;
    productGroup: ProductGroup;

}

export interface ApiDeleteProduct{
    productID: number;
    locationID: number;
}
export interface ApiUpdateProductsLocation {
  productsIDs: number[];
  locationID: number;
}

export interface ProductCategory {
    applicationUser: any;
    id: any;
    name: string;
    defaultCollection: boolean;
}
export interface ProductGroup {
  id: any;
  name: string;
  applicationUserId: any;
  applicationUser: any;
}

export interface ILocation {
    id: number;
    userLocation: number;
    name: string;
    address: string;
    city: string;
    country: string;
    email: string;
    phone: number;
    postalCode: number;
    state: string;
    unknownCollection: boolean;
    garbageCollection: boolean;
}

export interface FileResource {
    id: number;
    resourceId: any;
    filename: any;
}

export interface ReportAllGroupViewModel {
    inventoryReportID: number;
    reportModel: ReportGroupViewModel[];
}

export interface ReportGroupViewModel {
  groupName: string;
  productsFromGroup: InventoryProduct[];
  productsFromGroupFound: number;
}

export interface InventoryReport {
  id: number;
  dateTime: Date;
  location: string;
  uploader: string;
  products: InventoryProduct[];
  productsFound: number;
  salesforceReservationId: string;
}

export interface InventoryProduct {
    count: number;
    description: string;
    id: number;
    isFound: boolean;
    maxCount: number;
    name: string;
    tagEPC: string;
    tagName: string;
    tagPassword: string;
    tagTID: string;
    tagTamper: boolean;
    tagUserData: string;
    productGroupName: string;
}

export interface UserDetails {
    userName: string;
    userDefaultLang: string;
    userLicense: Date;
    email: string;
    finish: number;
    serviceEmail: string;
}

export interface LocationID {
    locationId: number;
}

export interface DashboardLocation {
    locationId: number;
    locationName: string;
    productsCount: number;
    lastReportId: number;
    lastReportTime: Date;
    unknownCollection: boolean;
}

export interface CsvTemplateHeaders {
    name: string;
    category: string;
    tagName: string;
    tagEPC: string;
    count: number;
    expirationDate: string;
    description: string;
}
export interface CsvExportProductList {
  location: string;
  name: string;
  category: string;
  tagName: string;
  tagEPC: string;
  count: number;
  expirationDate: string;
  description: string;
  productGroupName: string;
}
export interface CsvExportReport {
  name: string;
  tagName: string;
  tagEPC: string;
  productGroupName: string;
  description: string;
  isFound: string;
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export interface DefaultLanguage {
    language: string;
}

export class CSVRecord {
    public name: any;
    public location: any;
    public tagName: any;
    public tagEPC: any;
  }

  export class ImageUpload {

    File: File;
    ProductId: number;

    constructor(uploadFile: File, productId: number) {
        this.File = uploadFile;
        this.ProductId = productId;
    }

  }

  export interface IAppConfig {
    SignalR_Address: string;
    API_Address: string;
  }

  export interface CategoryDialogData {
    id: number;
    title: string;
    category: string;
    isEdit: boolean;
    newCategory: string;
  }
  export interface Category {
    id: number;
    name: string;
    defaultCollection: boolean;
  }
  export interface CategoryEdit {
    categoryName: string;
    newCategoryName: string;
  }

  export interface IDevice{
    latestVersion: string;
    url: string;
  }
