import { Component, OnInit } from '@angular/core';
import {FaqItem} from '@angular-material-extensions/faq';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  reader: FaqItem[] = [
    {
      question: 'Czytnik nie włącza się',
      answer: 'Możliwe całkowite rozładowanie baterii. Należy naładować urządzenie poprzez podpięcie dołączonego zasilacza sieciowego, a następnie spróbować ponownie włączyć czytnik RFID.'
    },
    {
      question: 'Nie można połączyć się z internetem',
      answer: '1. Połączenie z Internetem za pomocą danych komórkowych - Sprawdź czy karta nanoSIM została umieszczona w szufladce karty SIM, we właściwy sposób. Problem może występować po stronie dostawcy usługi lub został wykorzystany limit danych komórkowych. Sprawdź zasięg połączenia internetowego. '
      + '2. Połączenie z Internetem za pomocą sieci WiFi - Sprawdź zasięg połączenia WiFi. Sprawdź konfigurację routera.'
    },
    {
      question: 'Moduł RFID nie działa',
      answer: 'Należy sprawdzić czy pomarańczowy przełącznik przy uchwycie czytnika RFID jest ustawiony w tryb RFID, a nie w tryb odczytu kodów kreskowych. Prawidłowe działanie modułu RFID jest sygnalizowane podczas procesu skanowania przez wskaźnik statusu RFID znajdujący się z przodu czytnika (niebieska dioda).'
    },
    {
      question: 'Błąd karty SIM',
      answer: 'Należy upewnić się, że karta nanoSIM została prawidłowo umieszczona na tacy wpinanej do czytnika RFID oraz czy nie jest uszkodzona, bądź zanieczyszczona. W przypadku uszkodzonej karty nanoSIM należy wymienić ją na nową.'
    },
    {
      question: 'Brak możliwości wykonywania połączeń',
      answer: 'Należy upewnić się, że zasięg sieci komórkowej jest odpowiedni. W przypadku słabego zasięgu, należy udać się w miejsce, gdzie występuje lepszy zasięg komórkowy.'
    },
    {
      question: 'Brak dźwięku',
      answer: 'Możliwe, że urządzenie jest wyciszone. Należy dostosować/ustawić odpowiedni dla użytkownika poziom głośności za pomocą przycisku znajdującego się z boku ekranu czytnika.'
    },
    {
      question: 'Urządzenie nie ładuje się',
      answer: 'Możliwe całkowite lub zbyt duże rozładowanie baterii - należy podłączyć urządzenie do ładowarki i zaczekać 5 minut. Podczas poprawnego ładowania czytnika, dioda sygnalizująca ładowanie świeci w kolorze czerwonym.'
    },
    {
      question: 'Brak możliwości zrobienia zdjęcia',
      answer: 'Informacja "za mało miejsca do przechowywania" wskazuje na przepełnioną pamięć urządzenia- należy zwolnić miejsce w pamięci urządzenia poprzez usunięcie zbędnych plików/zdjęć.'
    },
    {
      question: 'Brak połączenia z Internetem przy korzystaniu z danych mobilnych',
      answer: 'Sprawdź czy przekaz danych mobilnych jest włączony. Należy je włączyć klikając w Ustawienia -> Sieć i Internet -> Użycie danych -> włączyć Dane mobilne. W przypadku korzystania z dwóch kart nanoSIM, należy przejść do Ustawień -> Sieć i Internet -> Karty SIM -> wybrać kartę SIM preferowaną dla mobilnej transmisji danych.'
    }
  ];

  web: FaqItem[] = [
    {

          question: 'Pojawia się komunikat o braku aktywacji konta',
          answer: 'Należy upewnić się, że konto zostało poprawnie aktywowane za pomocą dołączonego do zestawu jednorazowego Tokena. Konto można aktywować wypełniając formularz, który znajduje się na stronie https://issrfid.eu/active. Należy pamiętać, że bez potwierdzenia adresu email nie można aktywować konta Tokenem.'
        },
        {
          question: 'Pojawia się komunikat o złych danych logowania',
          answer: 'Należy upewnić się, czy wprowadzone dane logowania są prawidłowe. Aby ułatwić wpisywanie hasła, można wcisnąć ikonę "oka" (znajdującą się po prawej stronie wpisywanego hasła) i podejrzeć wpisywane hasło. UWAGA: Należy zwrócić uwagę na białe znaki (np. spacja)- dodanie ich do loginu lub hasła może być niezauważalne dla Użytkownika, aczkolwiek wpływa na sprawdzenie poprawności danych przy logowaniu.'
        },
        {
          question: 'Zgubienie lub nieuprawniona aktywacja tokenem',
          answer: 'Należy skontaktować się z administratorem - adres email: serwis@issrfid.com.'
        },
        {
          question: 'Nie widać wpisywanego hasła',
          answer: 'Kliknięcie w ikonę "oka" (znajdującą się po prawej stronie pola wpisywanego hasła) spowoduje wyświetlenie znaków użytych przy wpisywaniu hasła.'
        },
        {
          question: 'Odzyskiwanie hasła',
          answer: 'Aby odzyskać hasło, należy z menu logowania do Aplikacji WEB wybrać przycisk „Odzyskaj hasło”, a następnie podać nazwę użytkownika. Zostanie przesłany link do zmiany hasła na adres email przypisany do użytkownika. Po wejściu w link należy wypełnić formularz "zmiany hasła" właściwymi danymi i zatwierdzić. Po zalogowaniu się nowym hasłem, na adres email zostanie wysłana informacja o poprawnej zmianie hasła.'
        },
        {
          question: 'Pojawia się komunikat o braku potwierdzenia adresu email',
          answer: 'Należy potwierdzić adres email wchodząc w link podany w wiadomości email wysłanej po rejestracji konta.'
        },
        {
          question: 'Błędnie usunięty produkt',
          answer: 'Usunięte produkty trafiają do kosza, można je przywrócić lub całkowicie usunąć.'
        },
        {
          question: 'Problem z importowaniem produktów',
          answer: 'Aby prawidłowo importować produkty, należy zastosować plik ze wzorcem, pobrać go na komputer i uzupełnić. Plik ze wzorcem jest dostępny do pobrania, po zalogowaniu się na konto w Aplikacji WEB, na stronie produkty, w zakładce import. Uzupełniony plik należy wkleić poniżej przycisku do pobrania pliku wzorcowego. UWAGA: produkty przesłane w jednym pliku można dodać tylko do jednej lokalizacji wybranej w formularzu importu. Jeśli chcesz dodać produkty do wielu lokalizacji, musisz utworzyć osobne pliki lub ręcznie przypisać/przenieść produkty do różnych lokalizacji po wgraniu do Aplikacji WEB.'
        },
        {
          question: 'Wprowadzono zmiany w bazie danych na czytniku RFID, ale nie są widoczne w Aplikacji WEB',
          answer: 'Brak przeprowadzenia synchronizacji. Aby zapisać na serwerze wprowadzone zmiany do bazy danych (dodanie produktu, edycja, usunięcie, dodanie lokalizacji, wygenerowanie raportu), należy przeprowadzić synchronizację. Po synchronizacji zmiany będą widoczne w Aplikacji WEB. Aby móc zobaczyć wprowadzone zmiany w aplikacji WEB, należy odświeżyć stronę klikając w menu Aplikacji ikonkę odświeżenia.'
        }
  ];

  mobilna: FaqItem[] = [
    {
      question: 'Pojawia się komunikat „Brak połączenia z internetem"',
      answer: 'Należy sprawdzić ustawienia połączenia z Internetem za pomocą danych komórkowych (potrzebna karta nanoSIM) lub za pomocą sieci WiFi: 1. Połączenie z Internetem za pomocą danych komórkowych - Sprawdź czy karta nanoSIM została umieszczona w szufladce karty SIM, we właściwy sposób. Problem może występować po stronie dostawcy usługi lub został wykorzystany limit danych komórkowych. Sprawdź zasięg połączenia internetowego. '
      + '2. Połączenie z Internetem za pomocą sieci WiFi - Sprawdź zasięg połączenia WiFi. Sprawdź konfigurację routera.'
    },
    {
      question: 'Odzyskiwanie usuniętej aplikacji',
      answer: 'Najnowsza wersja aplikacji dostępna jest bezpłatnie w sklepie Google Play pod nazwą ISS RFID. Można także pobrać najnowszą wersję wchodząc na stronę issrfid.eu/update - pobierając plik "Mobile UHF RFID Reader BT/Neo".'
    },
    {
      question: 'Pojawia się komunikat „błędny login lub hasło”, spróbuj ponownie',
      answer: 'Komunikat "Błędnie wpisany login lub hasło"- należy sprawdzić poprawność wpisanych loginu i/lub hasła. *Błędne login i/lub hasło- należy wpisać poprawnie dane logowania i ponowić zalogowanie się na konto Użytkownika. * Poprawne login i/lub hasło- należy ponownie wpisać dane i zalogować się  na konto. Aby ułatwić wpisywanie hasła można wcisnąć ikonę "oka" (znajdującą się po prawej stronie wpisywanego hasła) i podejrzeć wpisywane hasło. UWAGA: Należy zwrócić uwagę na białe znaki (np. spacja), dodanie ich do loginu lub hasła może być niezauważalne dla Użytkownika, aczkolwiek wpływa na sprawdzenie poprawności danych przy logowaniu.'
    },
    {
      question: 'Pojawia się komunikat „konto jest nieaktywne”',
      answer: 'Należy upewnić się czy konto zostało poprawnie aktywowane za pomocą dołączonego do zestawu jednorazowego Tokena. Konto można aktywować wypełniając formularz, który znajduje się na stronie https://issrfid.eu/active. Należy pamiętać, że bez potwierdzenia adresu email nie można aktywować konta Tokenem.'
    },
    {
      question: 'Przy próbie logowania pojawia się okienko z pytaniem o zezwolenia',
      answer: 'Nadanie odpowiednich uprawnień jest konieczne, aby Aplikacja działała poprawnie. Zezwolenie Aplikacji ISS RFID na dostęp do zdjęć, multimediów i plików na urządzeniu – umożliwia przeprowadzenie synchronizacji bazy danych, dodawanie i edycję produktów. Zezwolenie Aplikacji ISS RFID na nawiązywanie połączeń telefonicznych i zarządzenie nimi - umożliwia korzystanie z modułu RFID wbudowanego w czytnik RFID.'
    },
    {
      question: 'Nie widać wpisywanego hasła',
      answer: 'Kliknięcie w ikonę "oka" (znajdującą się po prawej stronie pola wpisywanego hasła) spowoduje wyświetlenie znaków użytych przy wpisywaniu hasła.'
    },
    {
      question: 'Nie mogę zmienić języka aplikacji',
      answer: 'Język Aplikacji automatycznie dostosowuje się do języka urządzenia (czytnika RFID). Rozwiązanie nr 1 - zmiana języka czytnika RFID (Ustawienia -> System -> Języki i metody wprowadzania -> Języki -> Dodaj język i przesuń go na górę listy). Rozwiązanie nr 2 - zmiana języka Aplikacji polegająca na wciśnięciu odpowiedniej ikony flagi u dołu ekranu.'
    },
    {
      question: 'Po zalogowaniu się na konto nie widać żadnej lokalizacji',
      answer: 'Brak synchronizacji- należy wcisnąć przycisk synchronizacji (ikona przedstawiająca dwie zaokrąglone strzałki, na górze ekranu po lewej stronie), a następnie wcisnąć przycisk „Synchronizuj” widoczny w nowym okienku. Po udanej synchronizacji należy wcisnąć przycisk „Zamknij okno”.'
    },
    {
      question: 'W bazie danych znajduje się wiele produktów i trudno znaleźć właściwy',
      answer: 'Istnieje możliwość przeszukiwania bazy danych przez wpisanie nazwy produktu. Należy kliknąć „Szukaj…” i wpisać nazwę szukanego produktu. Uwaga: nazwa produktu, musi być wcześniej nadana i przypisana do produktu.'
    },
    {
      question: 'Podczas skanowania etykiet RFID tagi nie są odczytywane',
      answer: 'Sprawdź moc czytnika RFID. W razie potrzeby zwiększ jego moc przesuwając suwak siły sygnału w prawo.'
    },
    {
      question: 'Nie mogę odczytać etykiety RFID',
      answer: 'Należy zwiększyć moc czytnika i wykonać skanowanie ponownie. Należy również upewnić się, że etykieta RFID została zamocowana na odpowiednim podłożu oraz czy nie jest uszkodzona.'
    },
    {
      question: 'Pojawia się komunikat „brak formularza”',
      answer: 'Należy uzupełnić brakujące informacje o produkcie i spróbować ponownie zapisać produkt.'
    },
    {
      question: 'Wprowadzono zmiany w bazie danych na czytniku RFID, ale nie są widoczne w Aplikacji WEB',
      answer: 'Brak przeprowadzenia synchronizacji. Aby zapisać zmiany na serwerze, po wprowadzeniu zmian do bazy danych (dodanie produktu, edycja, usunięcie, dodanie lokalizacji, wygenerowanie raportu), należy przeprowadzić synchronizację. Po wykonaniu synchronizacji zmiany będą widoczne w Aplikacji WEB. Aby móc zobaczyć wprowadzone zmiany w Aplikacji WEB należy odświeżyć stronę klikając w menu Aplikacji ikonkę "odświeżenia" (ikona przedstawiająca dwie zaokrąglone strzałki).'
    },
    {
      question: 'Pojawia się komunikat „tag RFID jest już w bazie danych”',
      answer: 'Należy zeskanować inną etykietę RFID- taką, której nie zapisano jeszcze w bazie danych.'
    }
  ];

}
