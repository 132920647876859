import { Component, OnInit } from '@angular/core';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';
import { TranslateService } from '@ngx-translate/core';
import { AdministratorLocationModel, AdminSelectedLocation } from 'src/model/adminApiTypes';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-location-admin',
  templateUrl: './location-admin.component.html',
  styleUrls: ['./location-admin.component.scss']
})
export class LocationAdminComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  constructor(public service: AdministratorService,
    public translate: TranslateService,
    private dialog: MatDialog) { }


  locations$: Observable<AdministratorLocationModel[]>;
  location: AdministratorLocationModel[];
  sortBy = '';
  sortAsc = true;
  sort_str = 'id';
  searchText = '';
  p: any;
  isShown: boolean = false ;

  ngOnInit() {
    this.loadLocations();
    if (window.screen.width <= 884) {
      this.isShown = true;
    }
  }

  deleteLocation(id: number){
    this.blockUI.start();
    let data: AdminSelectedLocation = {
      locationId: id,
      userId: null
    };
    this.service.deleteLocation(data).subscribe(
      (res: any) => {
        this.loadLocations();
        console.log(res);
        this.blockUI.stop();
      },
      err => {
        console.log(err);
        this.blockUI.stop();
      },
    );
  }

  onSearchKey() {
    this.goToFirstPagination();
  }

  goToFirstPagination() {
    this.p = 1;
  }

  onDeleteLocationDialog(id: number) {
    let dialogData;
    this.translate.get('confirm_delete') .subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteLocation(id);
      }
    });
  }

  onAddUserToLocationDialog(id: number) {
    let dialogData;
    this.translate.get('add') .subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  loadLocations(){
    this.service.getLocations().subscribe(
      (res: any) => {
        this.locations$ = res;
        console.log(res);
      },
      err => {
        console.log(err);
      },
    );
  }

  onSort(sort_by: any) {
    this.sortBy = sort_by;
    if (this.sortAsc) {
      this.sort_str = sort_by + ' asc';
      this.sortAsc = false;
    } else {
      this.sort_str = sort_by + ' desc';
      this.sortAsc = true;
    }
  }

}
