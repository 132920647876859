import { Component,  Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { IProduct, ILocation } from 'src/model/apitypes';
import { AppConfig } from 'src/app/app.config';
import { ProductService } from 'src/app/services/product/product.service';
import { UserService } from 'src/app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-move-product',
  templateUrl: './move-product.component.html',
  styleUrls: ['./move-product.component.scss']
})
export class MoveProductComponent implements OnInit  {

  BaseURI = AppConfig.settings.API_Address;

  locations: ILocation[];
  selectedLocation: ILocation;
  unknownLocation: ILocation;
  currentLocation: ILocation;

  autoTicks = false;
  disabled = true;
  max = 1;
  min = 1;
  step = 1;
  thumbLabel = true;
  value = 1;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public dialogRef: MatDialogRef<MoveProductComponent>,
    public translate: TranslateService,
    public productService: ProductService,
    public userService: UserService,
    public notificationService: NotificationService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public product: IProduct) {}

    async ngOnInit() {
      if (this.product.count !== 1) {
        this.disabled = false;
      }
      this.max = this.product.count;
      this.userService.getUserLocations().subscribe(
        (res: ILocation[]) => {
          this.locations = res;
          this.selectedLocation = this.locations.filter(l => l.unknownCollection === true)[0];
          this.currentLocation = this.product.location;
        },
        err => {
          console.log(err);
        },
      );
    }

  public moveCountOfProductToOtherLocation(ProductID: number, Count: number, LocationID: number) {
    this.blockUI.start();
    if (this.selectedLocation.id !== this.product.location.id) {
      const productsToMove: ProductsToMove = {productID: ProductID, count: Count, locationID: LocationID };
      let toastrData;
      this.userService.moveProduct(productsToMove).subscribe(
        (res: any) => {
          if (res) {
            this.productService.loadAll();
            this.translate.get('product_moved_success').subscribe(value => { toastrData = value; } );
            this.toastr.success(toastrData);
            this.onClose(true);
          }
        },
        (err) => {
          this.onClose(true);
          console.log('Error:' + err);
        });
    } else {
      let toastrData;
      this.translate.get('moved_to_same_location').subscribe(value => { toastrData = value; } );
      this.toastr.warning(toastrData);
    }
  }

  onSelectLocation(l: ILocation) {
    this.selectedLocation = l;
  }
  onClose(isSaved: boolean) {
  this.dialogRef.close(isSaved);
  this.blockUI.stop();
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}

export interface ProductsToMove {
  productID: number;
  count: number;
  locationID: number;
}
