
import { UserService } from '../user/user.service';
import * as _ from 'node_modules/lodash';
import { Category, IProduct } from 'src/model/apitypes';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from 'src/app/app-state.service';
import { ProductService } from '../product/product.service';
import { Injectable } from '@angular/core';

@Injectable()
export class CategoryService {

  public categoriesNames: any[] = [];
  public allCategories: Category[] = [];
  public filteredCategories: Category[] = [];
  constructor(public userService: UserService,
              public translate: TranslateService,
              public appStateService: AppStateService) {}


  getCategoryName(category: Category): string {
    if (category.defaultCollection) {
      let defaultCategoryName;
      this.translate.get('default_category').subscribe(value => { defaultCategoryName = value; } );
      return defaultCategoryName;
    } else {
      return category.name;
    }
  }

  public async LoadUserCategories() {
    this.categoriesNames = [];
    this.allCategories = [];
    this.filteredCategories = [];
    this.userService.getUserCategories().subscribe(
      (res: Category[]) => {
        res.forEach(c => {
        this.categoriesNames.push(c.name);
        this.allCategories.push(c);
        this.filteredCategories.push(c);
      });
      // Sortowanie alfabetyczne
      this.categoriesNames.sort((a, b) => {
        const A = a.toLowerCase();
        const B = b.toLowerCase();
        return (A < B) ? -1 : (A > B) ? 1 : 0;
      });
      this.allCategories.sort((a, b) => {
        const A = a.name.toLowerCase();
        const B = b.name.toLowerCase();
        return (A < B) ? -1 : (A > B) ? 1 : 0;
      });
      this.filteredCategories.sort((a, b) => {
        const A = a.name.toLowerCase();
        const B = b.name.toLowerCase();
        return (A < B) ? -1 : (A > B) ? 1 : 0;
      });
    });
  }

  checkIfCategoryNameAlreadyExist(categoryName: string): Boolean {
    let foundSameCategoryName = false;
    this.allCategories.forEach( c => {
      if (c.name === categoryName) {
        foundSameCategoryName = true;
      }
    });
    return foundSameCategoryName;
  }
}


