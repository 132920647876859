import { Component, OnInit } from '@angular/core';


declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  downloadPdf() {
    const pdfUrl = './assets/formularz_zal_1.pdf';
    const pdfName = 'formularz_zal_1';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

}
