
import { OnInit, EventEmitter, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { InventoryReport, ReportAllGroupViewModel } from 'src/model/apitypes';
import { UserService } from '../user/user.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'node_modules/lodash';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { NgForm } from '@angular/forms';


@Injectable()
export class ReportService {
  public firstLoadOfReports = true;
  public reportsLoaded = false;
  public ReportsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  reports$: Observable<InventoryReport[]> ;
  private _reports = new BehaviorSubject<InventoryReport[]>([]);
  readonly reports = this._reports.asObservable();
  public reportsStore: { reports: InventoryReport[] } = { reports: [] };

  reportsGroups$: Observable<ReportAllGroupViewModel[]> ;
  private _reportsGroups = new BehaviorSubject<ReportAllGroupViewModel[]>([]);
  readonly reportsGroups = this._reportsGroups.asObservable();
  public reportsGroupStore: { reportsGroups: ReportAllGroupViewModel[] } = { reportsGroups: [] };
  public latestReportDate: NgbDate;
  @BlockUI() blockUI: NgBlockUI;
  constructor(public userService: UserService) {}

  public LoadUserReports(romDate: Date, toDate: Date) {
    this.blockUI.start();
    var body = {
      dateFrom: romDate,
      dateTo: toDate
    };
    this.userService.getUserReports(body).subscribe(
      (res: InventoryReport[]) => {
        this.reportsStore.reports = res;
        this._reports.next(Object.assign({}, this.reportsStore).reports);
        this.getLatestReportDate();
        this.RefreshReportList();
        this.reportsLoaded = true;
        this.ReportsLoaded.emit(true);
        this.blockUI.stop();

        console.log("report" + this._reports);    
      },
      err => {
        console.log(err);
        this.blockUI.stop();
      },
    );
  }
  
  public LoadUserGroupsReports(romDate: Date, toDate: Date){
    this.blockUI.start();
    var body = {
      dateFrom: romDate,
      dateTo: toDate
    };
    this.userService.GetUserGroupReports(body).subscribe(
      (res: ReportAllGroupViewModel[]) => {
        this.reportsGroupStore.reportsGroups = res;  
        this._reportsGroups.next(Object.assign({}, this.reportsGroupStore).reportsGroups);
        this.RefreshReportGroupList();
        this.blockUI.stop();
        console.log("reportGroups" + this._reportsGroups);      
      },
      err => {
        console.log(err);
        this.blockUI.stop();
      },
    );
  }

  private RefreshReportGroupList() {
    this.reportsGroups$ = this.reportsGroups;
    //console.log(JSON.stringify(this.reportsGroups));
  }

  private RefreshReportList() {
    this.reports$ = this.reports;
    //console.log(JSON.stringify(this.reports));
  }

  public FilterReportsByDate(fromDate: NgbDate, toDate: NgbDate) {
    if (toDate && fromDate)  {
      const from = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
      const to = new Date(toDate.year, toDate.month - 1, toDate.day + 1);
      this._reports.next(Object.assign({}, this.reportsStore).reports
      .filter(r => (
        ( new Date(r.dateTime) >= from ) &&
        ( new Date(r.dateTime) <= to )
        )));
    }

    if (fromDate && !toDate) {
      const from = new Date(fromDate.year, fromDate.month - 1, fromDate.day );
      const fromplusone = new Date(fromDate.year, fromDate.month - 1, fromDate.day + 1);
      this._reports.next(Object.assign({}, this.reportsStore).reports
      .filter(r =>
        ( new Date(r.dateTime) >= from ) &&
        ( new Date(r.dateTime) <= fromplusone )
        ));
    }
  }

  public TakeReportsByDate(fromDate: NgbDate, toDate: NgbDate) {
    if (toDate && fromDate)  {
      const from = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
      const to = new Date(toDate.year, toDate.month - 1, toDate.day + 1);
      this.LoadUserReports(from, to);
      this.LoadUserGroupsReports(from, to);
    }
  }

  public getLatestReportDate() {
    // Create array out of all reports
    let array: any = [];
    this._reports
    .subscribe(r => {
      array = r as InventoryReport[];
    });
    // Get latest date out of array of reports
    const date = new Date(Math.max.apply(null, array.map(e => {
      return new Date(e.dateTime);
    })));
    this.latestReportDate = { year: date.getFullYear(),
                              month: date.getMonth() + 1,
                              day: date.getDay() + 1,
                              equals: null,
                              before: null ,
                              after: null};
  }

  public ListReportFromLastNDays(days: any): InventoryReport[] {
    const currentDate = new Date();
    const currentDateWithOffset = new Date(currentDate);
    currentDateWithOffset.setDate(currentDateWithOffset.getDate() - days);
    return _.filter(this.reportsStore.reports, function(o) {
      return (
        (new Date(o.dateTime) > currentDateWithOffset) &&
        (o.dateTime != null)
      );
    });
  }


}


