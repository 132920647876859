import { UserService } from '../../services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from 'src/app/app-state.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  constructor(public service: UserService, 
    private toastr: ToastrService,
    private router: Router,
    public translate: TranslateService,
    public appState: AppStateService) { }

  ngOnInit() {
    this.service.formModel.reset();
  }

  get f() { return this.service.formModel.controls }

  get UserName() {
    return this.service.formModel.get('UserName');
}

  onSubmit() {
    this.service.register().subscribe(
      (res: any) => {
        if (res.succeeded) {
          this.service.formModel.reset();
          let keySuccess;
          let valueSuccess;
          this.translate.get('newAccountCreated').subscribe(value => { keySuccess = value; } );
          this.translate.get('registerSuccess').subscribe(value => { valueSuccess = value; } );
          this.toastr.success(keySuccess, valueSuccess);
          this.router.navigate(['/emailconfirm']);
        } else {
          res.errors.forEach(element => {
            switch (element.code) {
              case 'DuplicateUserName':
                let key;
                let valueOfKey;
                this.translate.get('AccountNameBusy').subscribe(value => { key = value; } );
                this.translate.get('registerFailed').subscribe(value => { valueOfKey = value; } );
                this.toastr.error(key, valueOfKey);
                break;
                case 'InvalidUserName':
                  let keyInvalidUser;
                let valueInvalidUser;
                this.translate.get('registerInvalidUser').subscribe(value => { keyInvalidUser = value; } );
                this.translate.get('registerInvalidName').subscribe(value => { valueInvalidUser = value; } );
                  this.toastr.error(keyInvalidUser, valueInvalidUser, {
                    enableHtml: true
                    });
                  break;
              default:
                let keyFailOther;
                let valueFailOther;
                this.translate.get('wrongPasswordInfo').subscribe(value => { keyFailOther = value; } );
                this.translate.get('registerError').subscribe(value => { valueFailOther = value; } );
              this.toastr.error(keyFailOther , valueFailOther, {
                enableHtml: true
                });
                break;
            }
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  openTerms(){
    let valueSuccess;
    this.translate.get('isPl').subscribe(value => { valueSuccess = value; } );
    if(valueSuccess === "No"){
      let pdfUrl = "";
      let pdfName = "";
      pdfUrl = './assets/Terms_of_use_and_cookie_policy.pdf';
        pdfName = 'Terms of use and cookie policy';
        FileSaver.saveAs(pdfUrl, pdfName);
    }
    else if(valueSuccess === "isPL"){
      window.open("/terms", "_blank");
    }
  }

}
