import { ToastrService } from 'ngx-toastr';
import { AdministratorService } from 'src/app/services/administrator/administrator.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from 'src/app/app-state.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-active',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.scss']
})
export class ActiveComponent implements OnInit {

  constructor(public service: UserService,
              public router: Router,
              public toastr: ToastrService,
              public appState: AppStateService,) {
   }

  ngOnInit() {

    this.service.formActiveModel.reset();
  }

onSubmit() {
  this.service.activateUserAccountByToken().subscribe(
    (res: any) => {
      if (res.message === 'Success') {
        this.service.formActiveModel.reset();
          this.toastr.success('udało się, konto zostało aktywowane', 'Konto aktywowane prawidłowo');
          this.router.navigate(['/user/login']);
      }
    },
    err => {
      if(err.status === 400)
      {
        this.toastr.error('Nie można aktywować konta','Skontaktuj się z administratorem');
      }
      else if(err.status === 404)
      {
        this.toastr.error('Nie można aktywować konta','Podany token jest nieprawidłowy');
      }
      else if (err.status === 401) {
        this.toastr.error('Adres e-mail nie został potwierdzony', 'Potwierdź adres klikając w przesłany link potwierdzający, aby móc aktywować konto');
      }
      else if (err.status === 409) {
        this.toastr.success('Konto jest już aktywne', 'Nie trzeba aktywować konta, ponieważ jest już aktywne');
        this.router.navigateByUrl('/user/login');
      }
      else if (err.status === 403) {
        this.toastr.error('Podany użytkownik nie istnieje', 'Sprawdź nazwę użytkownika');
      }
      else
      {
        this.toastr.error('Błąd podczas aktywacji konta','Skontaktuj się z administratorem');
      }
      console.log(err);
    }
  );
}
}

