import * as _ from 'node_modules/lodash';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';


export class StoreService {

  constructor() {}
  // Store Add Product Dialog Data
  public addProductFormStored = false;
  addProductImageUrl: string;
  addProductForm: FormGroup = new FormGroup({
    $key: new FormControl(0),
    name: new FormControl('', Validators.required),
    location: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    description: new FormControl(''),
    code: new FormControl(''),
    count: new FormControl(1, Validators.required),
    price: new FormControl(0),
    markup: new FormControl(0),
    tagName: new FormControl(''),
    tagEPC: new FormControl('', Validators.required),
    tagUserData: new FormControl(''),
    tagPassword: new FormControl(''),
    expirationDate: new FormControl(''),
    tagTID: new FormControl(''),
    productGroupName: new FormControl('')
  });
  // Store Add Location Dialog Window
  public addLocationFormStored = false;
  addLocationForm: FormGroup = new FormGroup ({
    $key: new FormControl(0),
    name: new FormControl('', Validators.required),
    address: new FormControl(''),
    city: new FormControl(''),
    country: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    postalCode: new FormControl(''),
    state: new FormControl('')
  });
  // Products table columns visable settings
  imageColHidden = false;
  nameColHidden = false;
  countColHidden = false;
  categoryColHidden = false;
  tagNameColHidden = false;
  epcColHidden = false;
  locationColHidden = false;
  descriptionColHidden = true;
  expirationDateColHidden = true;
  operationsColHidden = false;
  productGroupColHidden = false;
  // Store Report Search Date Filter
  reportFilterFromDate: NgbDate;
  reportFilterToDate: NgbDate;
  // Store Add Product Dialog Methods
  SaveAddProductData(form: any, image: any) {
    this.addProductForm.reset();
    this.addProductForm.setValue(form.value);
    this.addProductImageUrl = image;
    this.addProductFormStored = true;
  }
  ReadAddProductData() {
    return this.addProductForm;
  }
  ReadAddProductImageUrl() {
    return this.addProductImageUrl;
  }
  // Store Add Location Dialog Methods
  SaveAddLocationData(form: any) {
    this.addLocationForm.setValue(form.value);
    this.addLocationFormStored = true;
  }
  ReadAddLocationData() {
    return this.addLocationForm;
  }
}


