import { Injectable } from "@angular/core";
import { ILocation, Category } from 'src/model/apitypes';
import { User } from './models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AppStateService {
  public currentPage: any;
  public CurrentUser: User;
  public userName: any;
  public Working: Boolean;
  public selectedLocation: ILocation = null;
  public selectedCategory: Category = null;
  public notificationCount: Number = 0;
  public notificationExist: Boolean = false;
  public language: String = null;
  public date: Date;
  public email: any;
  public finish: number;
  public serviceEmail: string;

  clearSelectedFilters() {
    this.selectedCategory = null;
    this.selectedLocation = null;
  }

  removeUser(){
    this.CurrentUser = null;
  }

  updateNotifications(count: any) {
    this.notificationCount = count;
    if (count > 0) {
      this.notificationExist = true;
    } else {
      this.notificationExist = false;
    }
  }
}

