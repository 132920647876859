import { UserService } from '../services/user/user.service';
import { Component, OnInit, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { SearchComponent } from '../pages/product-search/product-search.component';
import { DecimalPipe } from '@angular/common';
import { IProduct, UserDetails, DefaultLanguage } from 'src/model/apitypes';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { AppStateService } from '../app-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../services/product/product.service';
import { NotificationService } from '../services/notification/notification.service';
import { ReportService } from '../services/report/report.service';
import { LocationService } from '../services/location/location.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent implements OnInit {
  userDetails;
  AllProducts: IProduct[] = [];
  products$: Observable<IProduct[]> ;
  filter = new FormControl('');
  isShown: boolean = false ;
  isFinish: boolean = false ;
  licenseIsFinish: boolean = false;
  isFinishToday: boolean = false;

  constructor(private router: Router,
    private userService: UserService,
    private pipe: DecimalPipe,
    public appState: AppStateService,
    public translate: TranslateService,
    public productService: ProductService,
    public reportService: ReportService,
    public notificationService: NotificationService,
    public locationService: LocationService) {
   }

   refreshPage() {
    window.location.reload();
 }

  async ngOnInit() {
    this.appState.currentPage = 'Dashboard';
    //this.userService.LoadUserProducts();
    if (window.screen.width <= 884) {
      this.isShown = true;
    }
    if (localStorage.getItem('token') != null) {
      this.userService.getUserDetails().subscribe(
        (res: UserDetails) => {
          this.appState.userName = res.userName;
          this.appState.language = res.userDefaultLang;
          this.appState.finish = res.finish;
          this.appState.email = res.email;
          this.appState.date = res.userLicense;
          this.appState.serviceEmail = res.serviceEmail;
          this.setLanguage(res.userDefaultLang);

          if(this.appState.finish <= 30 && this.appState.finish > 0){
            this.isFinish = true;
        }

        if(this.appState.finish == 0){
          this.isFinishToday = true;
      }

        if(this.appState.finish < 0){
          this.licenseIsFinish = true;
          this.isFinish = false;
          this.isFinishToday = false;
      }
        },
        err => {
          console.log('appComponent GetuserDetails' + err);
          this.router.navigate(['/user/login']);
        },
      );
    }
    //this.productService.loadAll();
    this.locationService.LoadUserLocations();
    this.locationService.getLocationsGeneralInfo();
    this.notificationService.loadAll();
    this.notificationService.checkAllNotifications();
    // this.reportService.LoadUserReports();
    // this.reportService.LoadUserGroupsReports();
  }

  onLogout() {
    localStorage.setItem('token', null);
    localStorage.setItem('role', null);
    localStorage.setItem('userName', null);
    this.appState.removeUser();
    this.router.navigate(['/user/login']);
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  setLanguage(lang: string){
    if(lang === "en-US"){
      this.translate.use('en')
    }
    else if (lang === "pl-PL"){
      this.translate.use('pl')
    }
  }

  onLanguageChange(lang: string){
    let defaultLanguage: DefaultLanguage = {} as any;
    defaultLanguage.language = lang;
    if(lang == "en-US"){
      this.userService.setDefaultLanguage(defaultLanguage).subscribe(
        (res: any) =>
          {
          },
        (err) =>
          console.log("Error:" + err));
      this.translate.use('en')
    }
    else if (lang == "pl-PL"){

      this.userService.setDefaultLanguage(defaultLanguage).subscribe(
        (res: any) =>
          {
          },
        (err) =>
          console.log("Error:" + err));;
      this.translate.use('pl')
    }
  }
}
