import { Component, OnInit } from '@angular/core';
import { AppStateService } from './app-state.service';
import { UserService } from './services/user/user.service';
import { UserDetails, IAppConfig } from 'src/model/apitypes';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { User } from './models/user.model';
import { NotificationService } from './services/notification/notification.service';
import { ProductService } from './services/product/product.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public currentPage: any;
  title = 'Inventory Manager';

  constructor(public appState: AppStateService,
    public userService: UserService,
    public router: Router ) {
  }

  ngOnInit() {
  }


}


