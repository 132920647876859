import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'app-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss']
})
export class TableSettingsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TableSettingsComponent>,
              public storeService: StoreService) { }

  ngOnInit() {
  }
  onClose() {
    this.dialogRef.close();
  }

}
