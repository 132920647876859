import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { CategoryDialogData } from 'src/model/apitypes';
import { CategoryService } from 'src/app/services/category/category.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-category-dialog',
  templateUrl: './add-category-dialog.component.html',
  styleUrls: ['./add-category-dialog.component.scss']
})
export class AddCategoryDialogComponent {

  oldCategoryName = '';
  isEditingMode = false;

  constructor(
    public dialogRef: MatDialogRef<AddCategoryDialogComponent>,
    public translate: TranslateService,
    private toastr: ToastrService,
    public categoryService: CategoryService,
    @Inject(MAT_DIALOG_DATA) public data: CategoryDialogData) {
      this.checkIfUserIsInEditingMode();
    }


  onCloseClick(): void {
    this.dialogRef.close();
  }

  saveCategory() {
    if (this.isEditingMode === true) {
      if ( this.oldCategoryName !== this.data.category) {
        if (this.categoryService.checkIfCategoryNameAlreadyExist(this.data.category)) {
          this.toastCategoryAlreadyExit();
        } else {
          // tslint:disable-next-line: max-line-length
          this.dialogRef.close({id: this.data.id, title: this.data.title, category: this.oldCategoryName, isEdit: true, newCategory: this.data.category});
        }
      } else {
        this.toastCategoryAlreadyExit();
      }
    }
    if (this.isEditingMode === false) {
      if (this.categoryService.checkIfCategoryNameAlreadyExist(this.data.category)) {
        this.toastCategoryAlreadyExit();
      } else {
        this.dialogRef.close(this.data);
      }
    }
  }

  toastCategoryAlreadyExit() {
    let toastrData; this.translate.get('category_exist') .subscribe(value => { toastrData = value; } );
    this.toastr.warning(toastrData);
  }
  checkIfUserIsInEditingMode() {
    if (this.data.isEdit) {
      this.oldCategoryName = this.data.category;
      this.isEditingMode = true;
     }
  }
}
