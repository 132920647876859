import { Component, OnInit, PipeTransform } from '@angular/core';
import { AppStateService } from '../../app-state.service';
import { UserService } from '../../services/user/user.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { LocationComponent } from '../../dialogs/add-location/add-location.component';
import { startWith, map } from 'rxjs/operators';
import { ILocation } from '../../../model/apitypes'
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/services/product/product.service';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { LocationService } from 'src/app/services/location/location.service';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit {

  AllLocations: ILocation[] = [];
  locations$: Observable<ILocation[]> ;
  filter = new FormControl('');

  searchText = '';
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public pipe: DecimalPipe,
    public appState: AppStateService,
    private service: UserService,
    private dialog: MatDialog,
    public translate: TranslateService,
    public router: Router,
    private toastr: ToastrService,
    private productService: ProductService,
    public locationService: LocationService) {
  }

  isShown: boolean = false ;

  ngOnInit() {
    this.appState.currentPage = 'Locations';
    if (window.screen.width <= 884) {
      this.isShown = true;
    }
  }

  onCreate() {
    this.service.setLocation(null);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (window.screen.width <= 884) {
      dialogConfig.width = '90%';
    }
    else{
      dialogConfig.width = '60%';
    }
    const ref = this.dialog.open(LocationComponent, dialogConfig);
    ref.afterClosed().subscribe(isUpload => {
      if (isUpload) {
        this.locationService.LoadUserLocations();
      }
    });
  }

  onUpdateLocation(selectedLocation: ILocation) {
    this.service.setLocation(selectedLocation);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (window.screen.width <= 884) {
      dialogConfig.width = '90%';
    }
    else{
      dialogConfig.width = '60%';
    }
    const ref = this.dialog.open(LocationComponent, dialogConfig);
    ref.afterClosed().subscribe(isUpload => {
      if (isUpload) {
        this.locationService.LoadUserLocations();
      }
    });
  }

  moveToSelectedLocation(l: ILocation) {
    this.appState.selectedLocation = l;
    this.productService.SearchProductsForSelectedLocationAndCategory(l.id, null);
    this.productService.createCategoriesListForLocation(l.name);
    this.router.navigate(['/home/product-search']);
  }

  deleteLocation(l: ILocation) {
    let dialogData;
    this.translate.get('confirm_delete_location').subscribe(value => { dialogData = value; } );
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let toastrData;
        this.blockUI.start();
        this.service.deleteLocation(l).subscribe(
          (res: any) => {
            this.translate.get('delete_location_success').subscribe(value => { toastrData = value; } );
            this.productService.deleteProduct(res);
            this.toastr.success(toastrData);
            this.locationService.LoadUserLocations();
            this.blockUI.stop();
          },
          (err) => {
            console.log('Error:' + err);
            this.blockUI.stop();
          }
        );
      }
    });
  }

}
