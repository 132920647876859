import { Component } from '@angular/core';
import { AddCategoryDialogComponent } from '../add-category-dialog/add-category-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from 'src/app/services/product/product.service';
import { CategoryService } from 'src/app/services/category/category.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UserService } from 'src/app/services/user/user.service';
import { Category , CategoryEdit} from 'src/model/apitypes';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { ToastrService } from 'ngx-toastr';
import { AppStateService } from 'src/app/app-state.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.scss']
})
export class ManageCategoriesComponent {

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public productService: ProductService,
    public userService: UserService,
    public dialogRef: MatDialogRef<AddCategoryDialogComponent>,
    private dialog: MatDialog,
    public translate: TranslateService,
    public categoryService: CategoryService,
    private toastr: ToastrService,
    public appStateService: AppStateService) { }


  onClose(): void {
    this.dialogRef.close();
  }

  onAddCategory() {
    let dialogData;
    this.translate.get('add_new_category').subscribe(value => { dialogData = value; } );
    const ref = this.dialog.open(AddCategoryDialogComponent, {
      width: '400px',
      data: {id: null, title: dialogData, category: '', isEdit: false, newCategory: null}
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.blockUI.start();
        let toastrData;
        this.userService.addCategory(result.category).subscribe(
          async (res: any) => {
            this.translate.get('create_category_success') .subscribe(value => { toastrData = value; } );
            this.toastr.success(toastrData);
            await this.categoryService.LoadUserCategories();
            this.blockUI.stop();
          },
          (err) => {
            this.translate.get('create_category_failed') .subscribe(value => { toastrData = value; } );
            console.log(err);
            this.blockUI.stop();
          }
         );
      }
    });
  }

  onDeleteCategory(category: Category) {
    let dialogData;
    this.translate.get('confirm_delete').subscribe(value => { dialogData = value; } );
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.blockUI.start();
        this.userService.deleteCategory(category).subscribe(
          async (res: any) => {
            let toastrData; this.translate.get('delete_category_success') .subscribe(value => { toastrData = value; } );
            this.toastr.success(toastrData);
            this.appStateService.selectedCategory = null;
            await this.categoryService.LoadUserCategories();
            this.productService.loadAll();
            this.blockUI.stop();
          },
          (err) => {
            console.log(err);
            this.blockUI.stop();
          }
         );
      }
    });
  }
  onEditCategory(category: Category) {
    let dialogData;
    this.translate.get('edit_category').subscribe(value => { dialogData = value; } );
    const ref = this.dialog.open(AddCategoryDialogComponent, {
      width: '400px',
      data: {id: category.id, title: dialogData, category: category.name, isEdit: true, newCategory: null},
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.blockUI.start();
        let toastrData;
        const categoryEdit: CategoryEdit = { categoryName: result.category, newCategoryName: result.newCategory};
        this.userService.editCategory(categoryEdit).subscribe(
          async (res: any) => {
            this.translate.get('edit_category_success') .subscribe(value => { toastrData = value; } );
            this.toastr.success(toastrData);
            this.appStateService.selectedCategory = null;
            await this.categoryService.LoadUserCategories();
            this.productService.loadAll();
            this.blockUI.stop();
          },
          (err) => {
            this.translate.get('edit_category_failed') .subscribe(value => { toastrData = value; } );
            console.log(err);
            this.blockUI.stop();
          }
         );
      }
    });
  }


}
