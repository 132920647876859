import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { IProduct } from 'src/model/apitypes';
import { MatDialogRef } from '@angular/material';
import { ProductService } from 'src/app/services/product/product.service';
import { AppConfig } from 'src/app/app.config';
import { LocationService } from 'src/app/services/location/location.service';
import { CategoryService } from 'src/app/services/category/category.service';

interface Alert {
  type: string;
  message: string;
}
const ALERTS: Alert[] = [{
  type: 'success',
  message: 'This is an success alert',
}, {
  type: 'info',
  message: 'This is an info alert',
}, {
  type: 'warning',
  message: 'This is a warning alert',
}, {
  type: 'danger',
  message: 'This is a danger alert',
}, {
  type: 'primary',
  message: 'This is a primary alert',
}, {
  type: 'secondary',
  message: 'This is a secondary alert',
}, {
  type: 'light',
  message: 'This is a light alert',
}, {
  type: 'dark',
  message: 'This is a dark alert',
}
];

@Component({
  selector: 'app-info-product',
  templateUrl: './info-product.component.html',
  styleUrls: ['./info-product.component.scss']
})
export class InfoProductComponent implements OnInit {

  BaseURI = AppConfig.settings.API_Address;
  alerts: Alert[];

  constructor(public userService: UserService,
    public dialogRef: MatDialogRef<InfoProductComponent>,
    public productService: ProductService,
    public locationService: LocationService,
    public categoryService: CategoryService) { }

  public product: IProduct;
  ngOnInit() {
    this.product = this.userService.getProduct();
    this.alerts = Array.from(ALERTS);
  }

  onClose() {
    this.dialogRef.close();
  }



}
