import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams } from "@angular/common/http";
import { IProduct, ILocation, InventoryReport, DefaultLanguage, ReportAllGroupViewModel } from 'src/model/apitypes';
import { AppComponent } from '../../app.component';
import { AppConfig } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  header = new HttpHeaders().set('Content-type', 'application/json');

  constructor(private fb: FormBuilder, private http: HttpClient) {
   }

  formModel = this.fb.group({
    UserName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.-]*$/)]],
    Email: ['', Validators.email],
    FullName: [''],
    isChecked: [false, Validators.requiredTrue],
    Passwords: this.fb.group({
      Password: ['', [Validators.required, Validators.minLength(4)]],
      ConfirmPassword: ['', Validators.required]
    }, { validator: this.comparePasswords })
  });

  formActiveModel = this.fb.group({
    UserName: [''],
    Token: ['']
  });

  comparePasswords(fb: FormGroup) {
    const confirmPswrdCtrl = fb.get('ConfirmPassword');
    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb.get('Password').value != confirmPswrdCtrl.value) {
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      } else {
        confirmPswrdCtrl.setErrors(null);
      }
    }
  }

  activateUserAccountByToken(){
    var body = {
      UserName: this.formActiveModel.value.UserName,
      Token: this.formActiveModel.value.Token
    };
    return this.http.post(AppConfig.settings.API_Address + '/ApplicationUser/ActiveAccount', body)
}

  register() {
    var body = {
      UserName: this.formModel.value.UserName,
      Email: this.formModel.value.Email,
      FullName: this.formModel.value.FullName,
      Password: this.formModel.value.Passwords.Password
    };
    return this.http.post(AppConfig.settings.API_Address + '/ApplicationUser/Register', body);
  }

  login(formData) {
    return this.http.post(AppConfig.settings.API_Address + '/ApplicationUser/Login', formData);
  }

  getUserDetails() {
    return this.http.get(AppConfig.settings.API_Address + '/ApplicationUser/GetCurrentUserDetails');
  }

  setDefaultLanguage(formData){
    return this.http.post<DefaultLanguage>(AppConfig.settings.API_Address + '/ApplicationUser/SetDefaultLanguage', formData);
  }

  getUserProducts() {
    return this.http.get<IProduct[]>(AppConfig.settings.API_Address + '/Product/GetUserProducts');
  }

  getUserProductsFromLocation(body) {
    return this.http.post<IProduct[]>(AppConfig.settings.API_Address + '/Product/GetUserProductsFromLocation', body);
  }

  getUserProductsFromTrash() {
    return this.http.get(AppConfig.settings.API_Address + '/Product/GetUserProductsFromTrash');
  }

  createProduct(body) {
    console.log(body);
    return this.http.post(AppConfig.settings.API_Address + '/Product/Create', body);
  }

  updateProduct(body) {
    console.log(body);
    return this.http.post(AppConfig.settings.API_Address + '/Product/Update', body);
  }

  updateProductsLocations(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Product/UpdateLocations', body);
  }
  moveProduct(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Product/Move', body);
  }

  deleteProducts(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Product/DeleteProducts', body);
  }

  deleteProduct(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Product/Delete', body);
  }

  deletePermanentProduct(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Product/DeletePermanent', body);
  }
  deletePermanentaAllProducts(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Product/DeletePermanentList', body);
  }
  deletePermanentaSelectedProducts(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Product/DeletePermanentList', body);
  }
  restoreProduct(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Product/Restore', body);
  }
  restoreSelectedProducts(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Product/RestoreSelected', body);
  }

  selectedProduct: IProduct;
  setProduct(product: IProduct){
    this.selectedProduct = product;
  }

  AllProducts: IProduct[] = [];
  public LoadUserProducts(){
    this.getUserProducts().subscribe(
      (res: IProduct[]) => {
        this.AllProducts = res;
      },
      err => {
        console.log(err);
      },
    );
  }

  getProduct() {
    return this.selectedProduct;
  }

  public selectedLocation: ILocation;
  public setLocation(location: ILocation) {
    this.selectedLocation = location;
  }

  public getLocation(){
    return this.selectedLocation;
  }

  getUserLocations() {
    return this.http.get(AppConfig.settings.API_Address + '/Location/GetUserLocations');
  }

  getUserLocationsGeneralInfo(){
    return this.http.get(AppConfig.settings.API_Address + '/Location/GetGeneralInformation');
  }

  public deleteLocation(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Location/DeleteLocation', body);
  }

  uploadProductPicture(body){
    return this.http.post(AppConfig.settings.API_Address + '/FileResource/UploadProductPicture', body);
  }

  createLocation(body){
    return this.http.post(AppConfig.settings.API_Address + '/Location/Create', body);
  }

  updateLocation(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Location/Update', body);
  }
  selectedReport: InventoryReport;
  getUserReports(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Report/GetUserReports', body);
  }

  selectedGroupReport: ReportAllGroupViewModel;
  GetUserGroupReports(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Report/GetUserGroupReports', body);
  }

  deletePermanentReport(body) {
    return this.http.post(AppConfig.settings.API_Address + '/Report/DeletePermanentReport', body);
  }

  getImage(){
    return this.http.get(AppConfig.settings.API_Address + '/FileResource/GetImage');
  }

  deleteImage(body){
    return this.http.post(AppConfig.settings.API_Address + '/FileResource/DeleteProductPicture', body);
  }

  downloadExcelTemplate() {
    return this.http.get(AppConfig.settings.API_Address + '/FileResource/DownloadExcelTemplate', {responseType: 'blob'});
  }

  uploadProductsForImport(body){
    return this.http.post(AppConfig.settings.API_Address + '/ImportProduct/Import', body);
  }
  getUserCategories() {
    return this.http.get(AppConfig.settings.API_Address + '/ProductCategory/Get');
  }

  deleteCategory(body) {
    return this.http.post(AppConfig.settings.API_Address + '/ProductCategory/DeleteCategory', body);
  }
  addCategory(query) {
    let params = new HttpParams();
    params = params.append('categoryName', query);
    return this.http.get(AppConfig.settings.API_Address + '/ProductCategory/Add', { params: params } );
  }
  editCategory(body) {
    return this.http.post(AppConfig.settings.API_Address + '/ProductCategory/EditCategory', body );
  }

}
