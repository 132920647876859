import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import * as _ from 'node_modules/lodash';
import { IProduct } from 'src/model/apitypes';
import { ProductService } from '../../services/product/product.service';
@Pipe({
  name: 'searchProductPipe',
  pure: false
})
export class SearchProductPipe implements PipeTransform {

  transform(list: IProduct[], searchText: string, pipe: PipeTransform): IProduct[] {
    if (!list) { return []; }
    if (!searchText) { return list; }

    list.forEach(product => {
      if (product.name === null) { product.name = ''; }
      if (product.tagName === null) { product.tagName = ''; }
      if (product.epcs === null) { product.epcs = []; }
      if (product.category === null) { product.category = ''; }
      if (product.productGroup === null) { product.productGroup = {id: null, name: '', applicationUserId: null, applicationUser: null }; }
    });
    searchText = searchText.toLowerCase();
    return list.filter( item => {
          return item.name.toLowerCase().includes(searchText)
          || item.tagName.toLowerCase().includes(searchText)
          || item.epcs.find(a => a.toLowerCase().includes(searchText))
          || item.category.toLowerCase().includes(searchText)
          || item.productGroup.name.toLowerCase().includes(searchText);
        });
      }
}
