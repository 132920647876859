import { AuthGuard } from './auth/auth.guard';
import { AdminAuthGuard } from './auth/admin-auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './user/user.component';
import { RegistrationComponent } from './user/registration/registration.component';
import { LoginComponent } from './user/login/login.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './pages/product-search/product-search.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LocationComponent } from './dialogs/add-location/add-location.component';
import { LocationSearchComponent } from './pages/location-search/location-search.component';
import { ReportSearchComponent } from './pages/report-search/report-search.component';
import { NotificationSearchComponent } from './pages/notification-search/notification-search.component';
import { ProductTrashComponent } from './pages/product-trash/product-trash.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HomeAdminComponent } from './admin/home-admin/home-admin.component';
import { DashboardAdminComponent } from './admin/dashboard-admin/dashboard-admin.component';
import { UsersAdminComponent } from './admin/users-admin/users-admin.component';
import { LocationAdminComponent } from './admin/location-admin/location-admin.component';
import { UpdateComponent } from './pages/update/update.component';
import { NotificationAdminComponent } from './admin/notification-admin/notification-admin.component';
import { ProductAdminComponent } from './admin/product-admin/product-admin.component';
import { RecoverPwdComponent } from './user/recoverpwd/recoverpwd.component';
import { ActiveComponent } from './user/active/active.component';
import { EmailconfirmComponent } from './user/emailconfirm/emailconfirm.component';
import { AboutuserComponent } from './pages/aboutuser/aboutuser.component';
import { TermsComponent } from './user/terms/terms.component';
import { SupportComponent } from './pages/support/support.component';
import { RodoComponent } from './user/rodo/rodo.component';
import { FaqComponent } from './user/faq/faq.component';
import { FaqEnComponent } from './user/faq-en/faq-en.component';

const routes: Routes = [
  {path: '', redirectTo: '/user/login', pathMatch: 'full'},
  {path: 'update', component: UpdateComponent},
  { path: 'active', component: ActiveComponent },
  { path: 'emailconfirm', component: EmailconfirmComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'terms/rodo', component: RodoComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'faq-en', component: FaqEnComponent },
  {
    path: 'user', component: UserComponent,
    children: [
      { path: 'registration', component: RegistrationComponent },
      { path: 'login', component: LoginComponent },
      { path: 'recoverpassword', component: RecoverPwdComponent }
    ]
  },
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'product-search', component: SearchComponent },
      { path: 'product-trash', component: ProductTrashComponent },
      { path: 'registration', component: RegistrationComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'location-search', component: LocationSearchComponent },
      { path: 'report-search', component: ReportSearchComponent },
      { path: 'notification-search', component: NotificationSearchComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'support', component: SupportComponent },
      { path: 'aboutuser', component: AboutuserComponent }
    ]
  },
  {path: 'admin', component: HomeAdminComponent, canActivate: [AdminAuthGuard],
  children: [
    { path: '', redirectTo: 'dashboard-admin', pathMatch: 'full' },
    { path: 'dashboard-admin', component: DashboardAdminComponent },
    { path: 'users-admin', component: UsersAdminComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'location-admin', component: LocationAdminComponent },
    { path: 'notification-admin', component: NotificationAdminComponent },
    { path: 'product-admin', component: ProductAdminComponent }
  ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
