import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ILocation } from 'src/model/apitypes';
import { LocationService } from 'src/app/services/location/location.service';
@Component({
  selector: 'app-info-location',
  templateUrl: './info-location.component.html',
  styleUrls: ['./info-location.component.scss']
})
export class InfoLocationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InfoLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public location: ILocation,
    public locationService: LocationService) { }

  ngOnInit() {
  }
  onClose() {
    this.dialogRef.close();
  }
}
