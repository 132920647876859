import { Time } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'node_modules/lodash';
import { AdministratorLocationModel } from 'src/model/adminApiTypes';

@Pipe({
  name: 'sortNotification',
  pure: false
})
export class SortNotificationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let l = <AdministratorLocationModel[]>value;
    // no filter args - skip
    if (!args) {
      return l;
    }
    // empty - skip
    if (!l || l.length === 0 || l.length === 1 || l === null) {
      return l;
    }

    // sort ASC or DESC
    let sort_str = <Time>args;
    let parts = _.split(sort_str, ' ');
    let dir = parts.length === 2 ? parts[1].toString().toLowerCase() : 'asc';
    let multiplier = 1;
    if(dir === 'desc'){
      multiplier = -1;
    }

    value.sort((a: any, b: any) => {
      if(a[parts[0]] === null){
        a[parts[0]] = "";
      }
      if(b[parts[0]] === null){
        b[parts[0]] = "";
      }
      console.log(b[parts[0]]);
      if (a[parts[0]] < b[parts[0]]){
        return -1 * multiplier;
      }
      else if (a[parts[0]] > b[parts[0]]){
        return 1 * multiplier;
      }
      else {
        return 0;
      }
    });
    return value;
  }

}
