import { Component, OnInit } from '@angular/core';
import {FaqItem} from '@angular-material-extensions/faq';

@Component({
  selector: 'app-faq-en',
  templateUrl: './faq-en.component.html',
  styleUrls: ['./faq-en.component.scss']
})
export class FaqEnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  reader: FaqItem[] = [
    {
      question: 'Reader does not turn on',
      answer: 'Possible complete battery discharge. Recharge the device by plugging in the included AC adapter, then try to turn the RFID reader on again.'
    },
    {
      question: 'Unable to connect to the internet',
      answer: '1. mobile data connection to the Internet - Check whether the nanoSIM card has been placed in the SIM card drawer, in the right way. The problem may be on the service provider side or the cellular data limit has been used. Check the range of the Internet connection. '
      + '2. Internet connection via WiFi - Check the range of your WiFi connection. Check your router configuration.'
    },
    {
      question: 'RFID module does not work',
      answer: 'Make sure that the orange switch at the RFID reader handle is set to RFID mode and not to barcode reading mode. Proper operation of the RFID module is indicated during the scanning process by the RFID status indicator on the front of the reader (blue LED).'
    },
    {
      question: 'SIM card error',
      answer: 'Make sure that the nanoSIM card is correctly placed in the tray plugged into the RFID reader and that it is not damaged or contaminated. If the nanoSIM card is damaged, replace it with a new one.'
    },
    {
      question: 'No ability to make calls',
      answer: 'Make sure that the mobile network coverage is adequate. In case of poor coverage, go to a place with better cellular coverage.'
    },
    {
      question: 'No sound',
      answer: 'It is possible that the device is muted. Adjust/set the volume level that suits you using the button on the side of the reader screen.'
    },
    {
      question: 'The device is not charging',
      answer: 'Possible complete or too much battery discharge - connect the device to the charger and wait 5 minutes. During the correct charging of the reader, the charging indicator light glows red.'
    },
    {
      question: 'No photo opportunities',
      answer: 'The message "not enough storage space" indicates that the device memory is overflowing- free up space in the device memory by deleting unnecessary files/photos.'
    },
    {
      question: 'No Internet connection when using mobile data',
      answer: 'Check if mobile data transfer is enabled. Turn it on by clicking on Settings -> Network and Internet -> Data Usage -> turn on Mobile Data. If you are using two nanoSIM cards, go to Settings -> Network and Internet -> SIM Cards -> select the SIM card preferred for mobile data.'
    }
  ];

  web: FaqItem[] = [
    {

          question: 'A message appears that the account has not been activated',
          answer: 'Make sure that the account is properly activated with the included disposable Token. The account can be activated by filling out the form, which can be found at https://issrfid.eu/active. Please note that without confirming your email address, you cannot activate your account with the Token.'
        },
        {
          question: 'A message about bad login credentials appears',
          answer: 'Make sure that the login information entered is correct. To make typing your password easier, you can press the "eye" icon (located to the right of the password you are typing) and preview the password you are typing. NOTE: Pay attention to the white characters (e.g. space)- adding them to the login or password may be unnoticeable to the User, although it affects the validation of the data at login.'
        },
        {
          question: 'Lost or unauthorized token activation',
          answer: 'You should contact the administrator - email address: serwis@issrfid.com.'
        },
        {
          question: 'Can not see the password',
          answer: 'Clicking on the "eye" icon (located to the right of the password entry field) will display the characters used when entering the password.'
        },
        {
          question: 'Password recovery',
          answer: 'To recover your password, select the "Recover Password" button from the WEB Application login menu, and then enter your username. A link to change the password will be sent to the email address assigned to the user. After accessing the link, fill out the "change password" form with the correct data and approve. After logging in with the new password, an email will be sent to the email address that the password has been changed correctly.'
        },
        {
          question: 'A message appears that the email address is not confirmed',
          answer: 'You must confirm your email address by going to the link provided in the email sent after account registration.'
        },
        {
          question: 'Erroneously deleted product',
          answer: 'Deleted products go to the trash, can be restored or completely deleted.'
        },
        {
          question: 'Problem with importing products',
          answer: 'To import products correctly, you need to apply the pattern file, download it to your computer and complete it. The pattern file is available for download, after logging into your WEB Application account, on the products page, under the import tab. The completed file should be pasted below the template file download button. NOTE: products uploaded in one file can only be added to one location selected in the import form. If you want to add products to multiple locations, you will need to create separate files or manually assign/assign products to different locations after uploading to the WEB Application.'
        },
        {
          question: 'Changes have been made to the database on the RFID reader, but are not visible in the WEB Application',
          answer: 'Failure to perform synchronization. To save on the server the changes made to the database (adding a product, editing, deleting, adding a location, generating a report), a synchronization must be performed. After synchronization, the changes will be visible in the WEB Application. To see the changes made in the WEB Application, refresh the page by clicking the refresh icon in the Application menu.'
        }
  ];

  mobilna: FaqItem[] = [
    {
      question: 'The message "No connection to the Internet" appears.',
      answer: 'Check the Internet connection settings via cellular data (nanoSIM card needed) or via WiFi network: 1. Internet connection via cellular data - Check whether the nanoSIM card has been placed in the SIM card drawer, in the right way. The problem may be on the side of the service provider or the mobile data limit has been used. Check the range of the Internet connection. '
      + '2. Internet connection via WiFi - Check the range of your WiFi connection. Check your router configuration.'
    },
    {
      question: 'Recovering a deleted application',
      answer: 'The latest version of the app is available free of charge in the Google Play store under the name ISS RFID. You can also download the latest version by going to issrfid.eu/update - downloading the file "Mobile UHF RFID Reader BT/Neo".'
    },
    {
      question: 'The message "wrong login or password" appears, try again',
      answer: 'Message "Incorrectly entered login or password"-check the correctness of the entered login and/or password. * Incorrect login and/or password-enter the login data correctly and log in to the User account again. * Correct login and/or password-enter the data again and log in to the account. To make typing the password easier, you can press the "eye" icon (located to the right of the password you are typing) and preview the password you are typing. NOTE: Pay attention to white characters (e.g. space), adding them to the login or password may be unnoticeable to the User, although it affects the validation of data at login.'
    },
    {
      question: 'he message "account is inactive" appears',
      answer: 'Make sure that the account is properly activated with the included disposable Token. The account can be activated by filling out the form, which can be found at https://issrfid.eu/active. Please note that without confirming your email address, you cannot activate your account with the Token.'
    },
    {
      question: 'When trying to log in, a pop-up appears asking for permissions',
      answer: 'Granting the appropriate permissions is necessary for the Application to work properly. Allowing ISS RFID Application to access photos, multimedia and files on the device - allows you to perform database synchronization, add and edit products. Allowing the ISS RFID Application to make and manage phone calls - allows you to use the RFID module built into the RFID reader.'
    },
    {
      question: 'Can not see the password',
      answer: 'Clicking on the "eye" icon (located to the right of the password entry field) will display the characters used when entering the password.'
    },
    {
      question: 'I can not change the language of the application',
      answer: 'The language of the Application automatically adjusts to the language of the device (RFID reader). Solution #1 - change the language of the RFID reader (Settings -> System -> Languages and input methods -> Languages -> Add language and move it to the top of the list). Solution #2 - change the language of the Application by pressing the appropriate flag icon at the bottom of the screen.'
    },
    {
      question: 'When I log into my account, I do not see any location',
      answer: 'No synchronization- press the synchronization button (the icon representing two rounded arrows, at the top left of the screen), and then press the "Synchronize" button visible in the new window. After successful synchronization, press the "Close window" button.'
    },
    {
      question: 'There are many products in the database and it is difficult to find the right one',
      answer: 'It is possible to search the database by entering the product name. You need to click "Search..." and enter the name of the product you are looking for. Note: the name of the product, must be previously given and assigned to the product.'
    },
    {
      question: 'When scanning RFID tags, the tags are not read',
      answer: 'Check the power of the RFID reader. If necessary, increase its power by moving the signal strength slider to the right.'
    },
    {
      question: 'I can not read the RFID tag',
      answer: 'Increase the power of the reader and perform the scan again. Also, make sure that the RFID tag is attached to a suitable substrate and that it is not damaged.'
    },
    {
      question: 'The message "no form" appears',
      answer: 'Fill in the missing product information and try saving the product again.'
    },
    {
      question: 'Changes have been made to the database on the RFID reader, but are not visible in the WEB Application',
      answer: 'Failure to perform synchronization. To save changes to the server, after making changes to the database (adding a product, editing, deleting, adding a location, generating a report), you need to perform synchronization. Once the synchronization is performed, the changes will be visible in the WEB Application. To see the changes made in the WEB Application, refresh the page by clicking on the "refresh" icon (an icon representing two rounded arrows) in the Application menu.'
    },
    {
      question: 'The message "RFID tag is already in the database" appears',
      answer: 'Scan another RFID tag-one that has not yet been stored in the database.'
    }
  ];
}
