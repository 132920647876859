import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AppStateService } from 'src/app/app-state.service';
import { User } from 'src/app/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { DefaultLanguage } from 'src/model/apitypes';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formModel = {
    UserName: '',
    Password: ''
  };

  showPass = document.getElementsByClassName('show') as HTMLCollectionOf<HTMLElement>;
  hidePass = document.getElementsByClassName('hide') as HTMLCollectionOf<HTMLElement>;
  passField = document.getElementsByClassName('pass') as HTMLCollectionOf<HTMLElement>;
  constructor(private service: UserService,
              private router: Router,
              private toastr: ToastrService,
              private appState: AppStateService,
              private translate: TranslateService) {

    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.toastrConfig.resetTimeoutOnDuplicate = true;
   }

  ngOnInit() {

  }

  onSubmit(form: NgForm) {
    this.service.login(form.value).subscribe(
      async (res: User) => {
        const newUser: User = {
          userName: res.userName,
          userRole: res.userRole,
          token: res.token
        };
        localStorage.setItem('token', res.token);
        localStorage.setItem('userRole', res.userRole);
        localStorage.setItem('userName', res.userName);
        this.appState.CurrentUser = newUser;
        if (res.userRole === 'Administrator') {
          this.router.navigateByUrl('/admin');
        } else {
          this.router.navigateByUrl('/home');
        }
      },
      err => {
        let keyError;
        let valueError;
        if (err.status === 400) {
          this.translate.get('loginInvalidNameOrPassword').subscribe(value => { keyError = value; } );
          this.translate.get('loginWrongData').subscribe(value => { valueError = value; } );
          this.toastr.error( keyError, valueError);
        } else if (err.status === 404) {
          this.translate.get('loginConnectProblem').subscribe(value => { keyError = value; } );
          this.translate.get('cannotLogin').subscribe(value => { valueError = value; } );
          this.toastr.error(keyError, valueError);
        } else if (err.status === 403) {
          this.translate.get('loginEmailNotConfirm').subscribe(value => { keyError = value; } );
          this.translate.get('loginWithConfirmInfo').subscribe(value => { valueError = value; } );
          this.toastr.error(keyError, valueError);
        } else if (err.status === 401) {
          this.translate.get('loginActiveAccount').subscribe(value => { keyError = value; } );
          this.translate.get('accountNotActived').subscribe(value => { valueError = value; } );
          this.toastr.error(keyError, valueError);
          this.router.navigateByUrl('/active');
        } else {
          this.translate.get('tryAgainLater').subscribe(value => { keyError = value; } );
          this.translate.get('cannotLogin').subscribe(value => { valueError = value; } );
          this.toastr.error(keyError, valueError);
        }
      }
    );
  }

  onShowPassword() {
    this.showPass[0].style.display = 'none';
    this.hidePass[0].style.display = 'inline';
    this.passField[0].setAttribute('type', 'text');
  }
  onHidePassword() {
    this.showPass[0].style.display = 'inline';
    this.hidePass[0].style.display = 'none';
    this.passField[0].setAttribute('type', 'password');
  }

  onLanguageChange(lang: string){
    let defaultLanguage: DefaultLanguage = {} as any;
    defaultLanguage.language = lang;
    if(lang == "en-US"){
      this.service.setDefaultLanguage(defaultLanguage).subscribe(
        (res: any) =>
          {
          },
        (err) =>
          console.log("Error:" + err));
      this.translate.use('en')
    }
    else if (lang == "pl-PL"){

      this.service.setDefaultLanguage(defaultLanguage).subscribe(
        (res: any) =>
          {
          },
        (err) =>
          console.log("Error:" + err));;
      this.translate.use('pl')
    }
  }

}
